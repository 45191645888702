import axios from "./axios.interceptor";
import baseUrl from "./api.util";

export const GET_QA_LIST = `${baseUrl}/account/customer/qa/my-activity`;
export const ASK_QUESTION = `${baseUrl}/account/customer/qa/ask`;
export const GET_FORUM_LIST = `${baseUrl}/common/qa`;
export const GET_FAQ_LIST = `${baseUrl}/customer/common/faqs`;
export const GET_PROBLEM_AREA_LIST = `${baseUrl}/customer/common/problems`;
export const QA_URL = `${baseUrl}/account/customer/qa`;
export const GET_PROBLEM_CATEGORY = `${baseUrl}/customer/common/problem-category`;
export const GET_LANGUAGES = `${baseUrl}/customer/common/languages`;
export const GET_PROBLEM_CATEGORY_DETAILS = `${baseUrl}/customer/common/problems-detais`;

export const getQAList = async () => {
  const res = await axios.get(`${GET_QA_LIST}?skip=0&limit=0`);
  return res;
}

export const askQuestion = async (requestBody) => {
  const res = await axios.post(ASK_QUESTION, requestBody);
  return res;
}

export const getForumList = async () => {
  const res = await axios.get(`${GET_FORUM_LIST}?skip=0&limit=0`);
  return res;
}

export const getForumDetails = async (id) => {
  const res = await axios.get(`${GET_FORUM_LIST}/${id}/details`);
  return res;
}

export const addAnswer = async (id, requestBody) => {
  const res = await axios.post(`${QA_URL}/${id}/answer`, requestBody);
  return res;
}

export const getFAQList = async () => {
  const res = await axios.get(`${GET_FAQ_LIST}?skip=0&limit=0`);
  return res;
}

export const getProblemAreaList = async (categoryId) => {
  let apiUrl = `${GET_PROBLEM_AREA_LIST}?skip=0&limit=0`;
  if (categoryId) {
    apiUrl += `&category=${categoryId}`;
  }
  const res = await axios.get(apiUrl);
  return res;
}

export const getProblemCategories = async () => {
  const res = await axios.get(GET_PROBLEM_CATEGORY);
  return res;
}

export const getProblemCategoryDetails = async (id) => {
  const res = await axios.get(`${GET_PROBLEM_CATEGORY_DETAILS}/${id}`);
  return res;
}

export const getLanguages = async () => {
  const res = await axios.get(GET_LANGUAGES);
  return res;
}
