import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "./DashboardNew";
import Forum from "../admins/forum";
import FAQ from "../admins/faq";
import ProblemArea from "../admins/problem-area";
import BookingList from "../../pages/admins/booking-list";
import ProblemDetails from "../../pages/admins/problem-area-details";
import Details from "./appointment-profile-details";
import BookingDetails from "./booking-details";
import MyQA from "../../pages/admins/my-qa";
import Video from "../videoCall/VideoChat";
import SuccessPayment from "../admins/success-payment";
import FailurePayment from "../admins/failure-payment";
import SuccessMobilePayment from "../admins/success-mobile-payment";
import FailureMobilePayment from "../admins/failure-mobile-payment";
import { LayoutSplashScreen } from "../../../_metronic";

export default function HomePage() {
  // useEffect(() => {
  //   console.log('Home page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/forum" component={Forum} />
        <Route path="/problem-area" component={ProblemArea} />
        <Route path="/faq" component={FAQ} />
        <Route path="/booking-list" component={BookingList} />
        <Route path="/problem-details/:id/:lang" component={ProblemDetails} />
        <Route path="/professional-details/:id" component={Details} />
        <Route path="/booking-details/:bookingId" component={BookingDetails} />
        <Route path="/video-call/:bookingId" component={Video} />
        <Route path="/myQA" component={MyQA} />
        <Route path="/success-payment" component={SuccessPayment} />
        <Route path="/failure-payment" component={FailurePayment} />
        <Route path="/success-mobile-payment" component={SuccessMobilePayment} />
        <Route path="/failure-mobile-payment" component={FailureMobilePayment} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
