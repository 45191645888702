import React from 'react';
import "../admins/forum.css";

const PaymentModal = (props) => {

    return (
        <div className={`row forum ${props.paymentInitialized ? "pointer-none" : ""}`} style={{margin:0}}>
            <div className={`modal ask-question-modal fade ${props.displayModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Are you sure you want to cancel this booking?</h5>
                </div>
                <div className="modal-body">
                  <div className="col-12 pt-3 pb-3 text-right">
                    <button type="button" className="btn btn-outline-primary" disabled={props.paymentInitialized} onClick={props.cancelBooking}>Cancel Booking</button>
                    <button type="button" className="btn btn-primary ml-3" disabled={props.paymentInitialized} onClick={() => props.payNow(props.popupBookingDetails)}>{props.paymentInitialized ? 'Initialize payment...' : 'Retry Payment'}</button>
                  </div>
                </div>
                </div>
            </div>
            </div>
          
        </div>
    )
}

export default PaymentModal;