export const timeArrConst = ["12:00 AM", "12:30 AM", "1:00 AM", "1:30 AM", "2:00 AM", "2:30 AM", "3:00 AM", "3:30 AM", "4:00 AM", "4:30 AM", "5:00 AM", "5:30 AM", "6:00 AM", "6:30 AM", "7:00 AM", "7:30 AM", "8:00 AM", "8:30 AM", "9:00 AM", "9:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM", "12:00 PM", "12:30 PM", "1:00 PM", "1:30 PM", "2:00 PM", "2:30 PM", "3:00 PM", "3:30 PM", "4:00 PM", "4:30 PM", "5:00 PM", "5:30 PM", "6:00 PM", "6:30 PM", "7:00 PM", "7:30 PM", "8:00 PM", "8:30 PM", "9:00 PM", "9:30 PM", "10:00 PM", "10:30 PM", "11:00 PM", "11:30 PM"];
export const Config = {
    Service: {
        Status: {
            Pending: 1,
            Approved: 2
        }
    },
    Booking: {
        Status: {
            Pending: 1,
            Confirmed: 2,
            Completed: 3,
            CanceledByCustomer: 4,
            CanceledByVendor: 5,
            Failed: 6,
            Timedout: 7
        },
        NumberStatus : {
            1: "Pending",
            2: "Confirmed",
            3: "Completed",
            4: "Cancelled By Customer",
            5: "Cancelled By Vendor",
            6: "Failed",
            7: "Timedout"
        }
    },
    Transaction: {
        Status: {
            1: "Pending",
            2: "Paid",
            3: "Failed",
            4: "Refund Initiated",
            5: "Refund Failed"
        }
    }
};
export const urls = {
    "/dashboard": "/dashboard",
    "/forum": "/problem-area",
    "/problem-area": "/problem-area",
    "/faq": "/faq",
    "/myQA": "/problem-area",
    "/booking-list": "/booking-list",
    "/problem-details": "/problem-area",
    "/professional-details": "/dashboard",
    "/booking-details": "/booking-list",
    "/video-call": "/booking-list"
};