import React, { useEffect, useCallback, useState } from 'react';
import "./forum.css";
import { getBookingDetails } from "../../crud/booking.crud";
import { toast } from 'react-toastify';
import {
    API_ERROR
} from "../../config/error-message";

const SuccessPayment = (props) => {
    const [bookingDetails, updateBookingDetails] = useState({});
    const [disablePage, toggleDisablePage] = useState(false);
    const [pageInitialized, updatePageInitialization] = useState(false);

    const getBookingData = useCallback(async (id) => {
        toggleDisablePage(true);
        const bookingResult = await getBookingDetails(id);
        toggleDisablePage(false);
        if (bookingResult.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.errorCode]);
            return;
        }
        if (bookingResult.data.data && bookingResult.data.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.data.errorCode]);
            return;
        }
        console.log("bookingResult ", bookingResult);
        // return;
        const resultData = bookingResult.data.data;

        const localProfessionalObj = {
            bookingId: resultData._id
        };
        localProfessionalObj.appointmentNumber = resultData.pin;
        
        updateBookingDetails({ ...localProfessionalObj });
    }, []);

    useEffect(() => {
        if (!pageInitialized) {
            const el = document.getElementsByClassName("custom-navigation-bar")[0];
            const el1 = document.getElementsByClassName("footer")[0];
            const urlParams = new URLSearchParams(window.location.search);
            const bookingIdParam = urlParams.get('id');
            if (el) {
                el.style.visibility = "hidden";
            }
            if (el1) {
                el1.style.visibility = "hidden";
            }
            if (bookingIdParam) {
                getBookingData(bookingIdParam);
            }
            updatePageInitialization(true);
        }
    }, [getBookingData, pageInitialized]);

    const openAppointmentPage = () => {
        props.history.push(`/booking-details/${bookingDetails.bookingId}`);
    };

    return (
        <div className="forum success-payment-outside">
            <div className={`success-payment-wrapper`}>
                {!disablePage ?
                <>
                    <h3 className="header-1">
                        You have successfully booked your appointment
                    </h3>
                    <h4 className="header-2">The Appointment Number is</h4>
                    <div className="appointment-number">{bookingDetails.appointmentNumber}</div>
                    <div className="button-wrapper">
                        <button className="btn btn-outline-primary" onClick={() => props.history.push("/dashboard")}>Move to Dashboard</button>
                        <button className="btn btn-primary" onClick={openAppointmentPage}>See Appointment</button>
                    </div>
                </> : <div>processing...</div> }
            </div>
        </div>
    )
}

export default SuccessPayment;