import React, {useEffect, useState} from 'react';
import "./problem-area.css";
import {getFAQList} from "../../crud/qa-forum.crud";


// const dummyCategories = [{
//     name: "Problem Areas"
// },{
//     name: "Professionals"
// }, {
//     name: "Academic"
// },{
//     name: "Professionals"
// }, {
//     name: "Problem Areas"
// },{
//     name: "Academic"
// }];

const FAQ = (props) => {
    // const [categories, updateCategory] = useState([]);
    const [faqList, updateFAQList] = useState([]);
    const [loadingQuestions, toggleLoadingQuestions] = useState(true);
    // const [showPrevButtons, togglePrevButton] = useState(false);
    // const [showNextButtons, toggleNextButton] = useState(false);
    // const [wrapperHovered, toggleHovering] = useState(false);
    // const [categoryArray, updateCategoryArray] = useState([]);

    // const handleNextPrevButtons = () => {
    //     if (document.getElementsByClassName("categories-wrapper")[0]) {
    //         if (document.getElementsByClassName("categories-wrapper")[0].clientWidth < document.getElementsByClassName("categories-wrapper")[0].scrollWidth) {
    //             togglePrevButton(true);
    //             toggleNextButton(true);

    //             if (document.getElementsByClassName("categories-wrapper")[0].scrollLeft === 0) {
    //                 togglePrevButton(false);
    //             }
    //             if (Math.round(document.getElementsByClassName("categories-wrapper")[0].scrollWidth - document.getElementsByClassName("categories-wrapper")[0].clientWidth) === Math.round(document.getElementsByClassName("categories-wrapper")[0].scrollLeft)) {
    //                 toggleNextButton(false);
    //             }
    //         } else {
    //             togglePrevButton(false);
    //             toggleNextButton(false);
    //         }
    //     }
    // };

    // const sideScroll = (element,direction,speed,distance,step) => {
    //     let scrollAmount = 0;
    //     let slideTimer = setInterval(function(){
    //         if(direction === 'left'){
    //             element.scrollLeft -= step;
    //         } else {
    //             element.scrollLeft += step;
    //         }
    //         scrollAmount += step;
    //         if(scrollAmount >= distance){
    //             handleNextPrevButtons();
    //             window.clearInterval(slideTimer);
    //         }
    //     }, speed);
    // }

    // const nextClick = () => {
    //     var container = document.getElementsByClassName('categories-wrapper')[0];
    //     sideScroll(container,'right',25,100,10);
    // };

    // const prevClick = () => {
    //     var container = document.getElementsByClassName('categories-wrapper')[0];
    //     sideScroll(container,'left',25,100,10);
    // };

    // window.onresize = () => {
    //     handleNextPrevButtons();
    // };

    // window.onload = () => {
    //     handleNextPrevButtons();
    // };

    const selectAnswer = (item) => {
        const localProf = [...faqList];
        localProf.forEach(each => {
            
            if (each._id === item._id) {
                each.selected = !each.selected;
            } else {
                each.selected = false;
            }
        });
        updateFAQList([...localProf]);
    };

    const displayNavs = (bool) => {
        // if (bool) {
        //     handleNextPrevButtons();
        // }
        // toggleHovering(bool);
    };

    // const getClass = (index) => {
    //     if (document.getElementById(`categoryName${index}`)) {
    //         const localArray = [...categoryArray];
    //         if (document.getElementById(`categoryName${index}`).clientHeight > 30) {
    //             document.getElementById(`categoryName${index}`).style.paddingTop = 7 + "px";
    //         } else {
    //             document.getElementById(`categoryName${index}`).style.paddingTop = 13 + "px";
    //         }
    //         localArray.push(index);
    //         updateCategoryArray([...localArray]);
    //     }
    //     return '';
    // }

    // useEffect(() => {
    //     // updateCategory([...dummyCategories]);
    // }, []);

    // useEffect(() => {
    //     if (dummyCategories.length !== categoryArray.length) {
    //         dummyCategories.forEach((each, index) => {
    //             getClass(index);
    //         });
    //     }
    // });

    const getAllFAQList = async () => {
        const faqResults = await getFAQList();
        toggleLoadingQuestions(false);
        if (faqResults.data.data && faqResults.data.data.data && faqResults.data.data.data.length) {
            let faqObj = {};
            const faqArr = [];
            faqResults.data.data.data.forEach(each => {
                faqObj = {};
                faqObj._id = each._id;
                faqObj.question = each.title;
                faqObj.answer = each.description;
                faqObj.selected = false;
                faqArr.push({...faqObj});
            });
            updateFAQList([...faqArr]);
        }
    };

    useEffect(() => {
        getAllFAQList();
    }, []);

    return (
        <div className="row problem-area faq" style={{margin:0}}>
            <div className="col-lg-12">
                {/* <div className="row" style={{margin: 0}} onMouseEnter={() => { displayNavs(false)}}>
                    <div className={`col-6 categories-heading pb-0`}>
                        <h4 className={`mb-0`}>Categories</h4>
                    </div>
                </div>
                <div className="whole-categories-wrapper">
                    <div className={`categories-wrapper pl-5`} style={{margin:0}}>
                        {categories.length ? categories.map((category, index) =>{
                            return <div className={`custom-card ${index === categories.length - 1 ? 'mr-0' : ''}`} key={index} onMouseEnter={() => { displayNavs(true)}}>
                                <div id={`categoryName${index}`} className="col-lg-12 pl-0 pr-0 category-name">
                                    {category.name}
                                </div>
                            </div>
                        }) : null}
                        
                    </div>
                    {showNextButtons && wrapperHovered ? 
                        <div className="next-button-wrapper" onClick={nextClick} id="slide" style={{backgroundImage: "url(" + require('../../../images/right-arrow.svg') + ")"}}></div> : null}
                        {showPrevButtons && wrapperHovered ?
                        <div className="prev-button-wrapper" onClick={prevClick} style={{backgroundImage: "url(" + require('../../../images/left-arrow.svg') + ")"}} id="slideprev"></div>
                     : null}
                </div> */}
            
                <div className="col-12 categories-heading" onMouseEnter={() => { displayNavs(false)}}>
                    <h4 className={`mb-0 problem-category-heading`}>FAQ's</h4>
                </div>
                <div className="col-12" onMouseEnter={() => { displayNavs(false)}}>
                    <div className="row" style={{margin: 0}}>
                        {faqList.length ? 
                            faqList.map((professional, index) => {
                                return <div className={`col-12 professional-wrapper
                                `} key={index}>
                                    <div className="row faq-wrapper-row" style={{margin: 0}}>
                                        <div className="col-12 p-0">
                                            <div className={`faq-question ${professional.selected ? 'bor-bot' : ''}`} onClick={() => selectAnswer({...professional})}>
                                            {!professional.selected ?
                                                <img style={{width:"10px", marginRight: "10px", verticalAlign: "middle"}} src={require('../../../images/plus.png')} alt="expand row" /> :
                                                <img style={{width:"10px", marginRight: "10px", verticalAlign: "middle"}} src={require('../../../images/minus.png')} alt="collapse row" /> }
                                                <div className="question-text">{professional.question}</div>
                                            </div>
                                            {!professional.selected ?
                                            null :
                                            <div className="professional-location answer">
                                                <div>{professional.answer}</div>
                                            </div> }
                                        </div>
                                    </div>
                                </div>
                            })
                        : <div className="text-center well col-12 color-black">
                        {!loadingQuestions ? 'No Questions Available!' : 'loading...'}    
                    </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FAQ;