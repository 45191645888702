import React, { useEffect, useState, useCallback } from 'react';
import { shallowEqual, useSelector } from "react-redux";
import "./dashboard.css"
import * as auth from "../../store/ducks/auth.duck";
import { getProfessionalDetails, getAvailability, bookSlot, notification } from "../../crud/home.crud";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { toast } from 'react-toastify';
import {
    API_ERROR, REQUIRED_ERROR,
    TIME_PASSED
} from "../../config/error-message";
import {
    timeArrConst,
    Config
} from "../../config/constants";
import PaymentModal from "./payment-modal";
import GalleryModal from "../admins/gallery-modal";
// import {API_SUCCESS} from "../../config/success-message";

const defaultImage = require('../../../images/imgAvtr.png');

const timeArr = [...timeArrConst];

const bookingAsList = [{
    value: "1",
    text: "Self"
}, {
    value: "2",
    text: "Parent"
}, {
    value: "3",
    text: "Guardian"
}, {
    value: "4",
    text: "Other"
}];

const Details = (props) => {

    const { isAuthorized, authData } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user != null,
            authData: auth.user
        }),
        shallowEqual
    );
    const [selectedProfile, selectProfile] = useState({});
    const [bookingDetails, updateBookingDetails] = useState({});
    const [displayEditableSlot, toggleEditableSlot] = useState(false);
    const [selectedDate, handleDateChange] = useState(new Date());
    const [serviceTimeHours, updateServiceTimeHours] = useState([]);
    const [selectedServiceTime, updateSelectedServiceTime] = useState("");
    const [selectedBookingAs, updateSelectedBookingAs] = useState(bookingAsList[0].value);
    const [pageInitialized, updatePageInitialization] = useState(false);
    const [disablePage, toggleDisablePage] = useState(false);
    const [datePickerOpened, toggleDatepickerOpened] = useState(false);
    const [bookingFor, updateBookingFor] = useState("");
    const [ageClassObject, updateAgeClassObject] = useState({
        age: "",
        class: ""
    });
    const [ageClassObjectError, updateAgeClassObjectError] = useState({
        age: "",
        class: ""
    });
    const [bookingForError, updateBookingForError] = useState("");
    const [globalConfigData, updateGlobalConfig] = useState({});
    const [paymentInitialized, togglePaymentInitialization] = useState(false);
    const [openPaymentPopup, togglePaymentPopup] = useState(false);
    const [popupBookingDetails, updatePopupBookingDetails] = useState({});
    const [openGalleryModal, toggleGalleryModal] = useState(false);
    const [currentImageIndex, updateCurrentImageIndex] = useState(-1);
    const [showPrevButtons, togglePrevButton] = useState(false);
    const [showNextButtons, toggleNextButton] = useState(true);

    const handleNextPrevButtons = () => {
        if (document.getElementsByClassName("categories-wrapper")[0]) {
            if (document.getElementsByClassName("categories-wrapper")[0].clientWidth < document.getElementsByClassName("categories-wrapper")[0].scrollWidth) {
                togglePrevButton(true);
                toggleNextButton(true);

                if (document.getElementsByClassName("categories-wrapper")[0].scrollLeft === 0) {
                    togglePrevButton(false);
                }
                if (Math.round(document.getElementsByClassName("categories-wrapper")[0].scrollWidth - document.getElementsByClassName("categories-wrapper")[0].clientWidth) === Math.round(document.getElementsByClassName("categories-wrapper")[0].scrollLeft)) {
                    toggleNextButton(false);
                }

            } else {
                togglePrevButton(false);
                toggleNextButton(false);
            }
        }
    };

    const sideScroll = (element, direction, speed, distance, step) => {
        let scrollAmount = 0;
        let slideTimer = setInterval(function () {
            if (direction === 'left') {
                element.scrollLeft -= step;
            } else {
                element.scrollLeft += step;
            }
            scrollAmount += step;
            if (scrollAmount >= distance) {
                handleNextPrevButtons();
                window.clearInterval(slideTimer);
            }
        }, speed);
    }

    const nextClick = () => {
        var container = document.getElementsByClassName('categories-wrapper')[0];
        sideScroll(container, 'right', 25, 100, 10);
    };

    const prevClick = () => {
        var container = document.getElementsByClassName('categories-wrapper')[0];
        sideScroll(container, 'left', 25, 100, 10);
    };

    window.onresize = () => {
        handleNextPrevButtons();
    };

    window.onload = () => {
        handleNextPrevButtons();
    };

    const displayNavs = useCallback((bool) => {
        if (bool) {
            handleNextPrevButtons();
        }
    }, []);

    const getFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const month = (date.getMonth() + 1).toString().length === 1 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const getTime = (timeStr, date) => {
        const time = timeStr.slice(0, timeStr.length - 3)
        const amPm = timeStr.slice(timeStr.length - 2)
        // console.log("time ", amPm, time)
        let d = new Date();
        if (date) {
            d = new Date(date);
        }
        if (amPm === "AM" && time.split(":")[0] === "12") {
            return new Date(new Date(new Date(new Date(d.setHours(0)).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
        }
        if (amPm === "PM" && time.split(":")[0] !== "12") {
            return new Date(new Date(new Date(new Date(d.setHours(Number(time.split(":")[0]) + 12)).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
        }
        return new Date(new Date(new Date(new Date(d.setHours(time.split(":")[0])).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
    };

    const checkCurrentTime = (times) => {
        const today = new Date().getTime();
        // console.log(getTime(timeArr[times[0] - 1]), today, getTime(timeArr[times[0] - 1]) > today);
        return getTime(timeArr[times[0] - 1]) > today;
    };

    const checkCurrentDay = (date) => {
        const today = new Date(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0)).getTime();
        const day = new Date(new Date(new Date(new Date(new Date(date).setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0)).getTime();
        // console.log("today ", today, day)
        return today === day;
    };

    useEffect(() => {
        if (authData && authData.personalInfo) {
            updateBookingFor(authData.personalInfo.name);
        }
    }, [authData]);

    const getDuration = (times) => {
        times.sort((a, b) => { return a - b });
        const startTime = timeArr[times[0] - 1];
        const endTime = timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0];
        return `${startTime} - ${endTime}`;
    };

    const getAllSlots = (sessionTime, slots, date) => {
        // need to check if sessionTime (1 hr) is more than slots

        let arr = slots;
        let limit = Number(sessionTime);
        let newArr = [];
        let obj = {};
        for (let i = 0; i < arr.length; i++) {

            if (arr[limit + i - 1]) {
                let arr1 = arr.slice(i, limit + i)
                obj = {};
                if (arr1[arr1.length - 1] - arr1[0] === limit - 1) {
                    obj.value = arr1;
                    if (checkCurrentDay(date)) {
                        if (checkCurrentTime(arr1)) {
                            newArr.push(obj);
                        }
                    } else {
                        newArr.push(obj);
                    }
                }
            }
        }
        return newArr;
    };

    const bookNow = async (serviceDetails) => {
        if (!isAuthorized) {
            toast.info("Please login to book an appointment");
            return;
        }

        let convenienceFee = 0;
        let gst = 0;
        console.log("service ", serviceDetails);
        const availabilityResult = await getAvailability(serviceDetails._id);
        console.log("availabilityResult ", availabilityResult)
        const localBookingData = { ...serviceDetails };
        if (globalConfigData) {
            convenienceFee = globalConfigData.convenienceFee ? globalConfigData.convenienceFee : 0;
            gst = globalConfigData.gstPercentage ?
                (localBookingData.cost + convenienceFee) * (globalConfigData.gstPercentage / 100) : 0;
        }
        localBookingData.convenienceFee = parseFloat(convenienceFee);
        localBookingData.gst = parseFloat(gst);
        localBookingData.total = parseFloat(localBookingData.cost + localBookingData.convenienceFee + localBookingData.gst);
        localBookingData.slotDuration = "";
        localBookingData.slotDate = "";
        localBookingData.slots = [];
        let localDateTimeObj = {};
        if (availabilityResult.data.data && availabilityResult.data.data.length) {
            availabilityResult.data.data.forEach(each => {
                let localSlots = getAllSlots(serviceDetails.sessionTime, each.availableSlots, each.date);
                if (localSlots.length) {
                    localDateTimeObj = {};
                    localDateTimeObj.date = each.date;
                    localDateTimeObj.availableSlots = localSlots;
                    localBookingData.slots.push({ ...localDateTimeObj });
                }
            });
        }
        console.log("localBookingData.slots ", localBookingData.slots)
        if (localBookingData.slots.length) {
            const localAvailableSlots = [];
            console.log("localBookingData.slots[0] ", localBookingData.slots)
            if (checkCurrentDay(localBookingData.slots[0].date)) {
                localBookingData.slots[0].availableSlots.forEach(each => {
                    if (checkCurrentTime(each.value)) {
                        localAvailableSlots.push({
                            text: getDuration(each.value),
                            value: JSON.stringify(each.value)
                        });
                    }
                });
                if (localAvailableSlots.length) {
                    localBookingData.slotDate = getFullDateFormat(new Date(localBookingData.slots[0].date));
                    localBookingData.slotDuration = getDuration(localBookingData.slots[0].availableSlots[0].value);
                    handleDateChange(new Date(localBookingData.slots[0].date));
                }
                console.log("localAvailableSlots ", localAvailableSlots)
                if (!localAvailableSlots.length && localBookingData.slots.length > 1) {
                    localBookingData.slotDate = getFullDateFormat(new Date(localBookingData.slots[1].date));
                    localBookingData.slotDuration = getDuration(localBookingData.slots[1].availableSlots[0].value);
                    handleDateChange(new Date(localBookingData.slots[1].date));
                    localBookingData.slots[1].availableSlots.forEach(each => {
                        localAvailableSlots.push({
                            text: getDuration(each.value),
                            value: JSON.stringify(each.value)
                        });
                    });
                }
            } else {
                console.log("in here ", localBookingData)
                localBookingData.slotDate = getFullDateFormat(new Date(localBookingData.slots[0].date));
                localBookingData.slotDuration = getDuration(localBookingData.slots[0].availableSlots[0].value);
                handleDateChange(new Date(localBookingData.slots[0].date));
                localBookingData.slots[0].availableSlots.forEach(each => {
                    localAvailableSlots.push({
                        text: getDuration(each.value),
                        value: JSON.stringify(each.value)
                    });
                });
            }


            updateServiceTimeHours([...localAvailableSlots]);
            if (localAvailableSlots.length) {
                updateSelectedServiceTime(localAvailableSlots[0].value);
            }

        }
        updateBookingDetails({ ...localBookingData });
        if (localBookingData.slotDate) {
            toggleEditableSlot(true);
            handleDateChange(new Date(localBookingData.slotDate));
        }

        const myScript = document.createElement("script");
        myScript.setAttribute("src", "https://securegw-stage.paytm.in/merchantpgpui/checkoutjs/merchants/YHewvf66616299277757.js");
        myScript.setAttribute("crossorigin", "anonymous");
        myScript.setAttribute("type", "application/javascript");
        document.body.appendChild(myScript);
    };

    const getProfessionalData = useCallback(async (id) => {
        toggleDisablePage(true);
        const professionalResult = await getProfessionalDetails(id);
        toggleDisablePage(false);
        if (professionalResult.data.errorCode) {
            toast.error(API_ERROR[professionalResult.data.errorCode]);
            return;
        }
        if (professionalResult.data.data && professionalResult.data.data.errorCode) {
            toast.error(API_ERROR[professionalResult.data.data.errorCode]);
            return;
        }
        console.log("professionalResult ", professionalResult);
        const resultData = professionalResult.data.data;

        const localProfessionalObj = {
            name: resultData.vendorData.personalInfo.name,
            profilePicture: resultData.vendorData.personalInfo.profilePicture,
            description: resultData.vendorData.personalInfo.description,
            designation: "",
            _id: resultData.vendorData._id,
            experience: "",
            email: resultData.vendorData.personalInfo.email,
            websiteUrl: resultData.vendorData.personalInfo.website,
            rating: 5, // needs to be updated with response data
            descriptionImages: [],
            services: []
        }
        if (resultData.vendorData.personalInfo.experience.year || resultData.vendorData.personalInfo.experience.month) {
            const year = resultData.vendorData.personalInfo.experience.year;
            const month = resultData.vendorData.personalInfo.experience.month;
            if (year) {
                localProfessionalObj.experience += `${year} year${year > 1 ? 's' : ''}`;
            }
            if (month) {
                localProfessionalObj.experience += ` ${month} month${month > 1 ? 's' : ''}`;
            }
        }
        if (resultData.vendorData.photos && resultData.vendorData.photos.length) {
            resultData.vendorData.photos.forEach(each => {
                localProfessionalObj.descriptionImages.push(each.url);
            });
        }
        if (resultData.serviceList && resultData.serviceList.length) {
            resultData.serviceList.forEach(each => {
                if (each.status === Config.Service.Status.Approved) {
                    localProfessionalObj.services.push({
                        ...each,
                        name: each.title,
                        description: each.description,
                        addressTitle: each.addressTitle,
                        _id: each._id,
                        cost: parseFloat(each.fees),
                        location: each.location
                    });
                }
            });
        }
        if (localProfessionalObj.services.length) {
            localProfessionalObj.addressTitle = localProfessionalObj.services[0].addressTitle;
        }

        if (resultData.vendorData.personalInfo.categories && resultData.vendorData.personalInfo.categories.length) {
            resultData.vendorData.personalInfo.categories.forEach((item, index) => {
                localProfessionalObj.designation += item.title;
                if (index !== resultData.vendorData.personalInfo.categories.length - 1) {
                    localProfessionalObj.designation += ", ";
                }
            });
        }
        console.log("localProfessionalObj ", localProfessionalObj);
        selectProfile({ ...localProfessionalObj });
        const globalConfigResult = await notification();
        setTimeout(() => {
            displayNavs(true);
        }, 1000);
        if (globalConfigResult.data.data) {
            updateGlobalConfig({ ...globalConfigResult.data.data });
        }
    }, [displayNavs]);

    useEffect(() => {
        if (!pageInitialized) {
            updatePageInitialization(true);
            if (props.match.params.id) {
                getProfessionalData(props.match.params.id);
            }
        }
    }, [props.match.params, pageInitialized, getProfessionalData]);

    const copyEmailToClipboard = () => {
        const tempInput = document.createElement("input");
        tempInput.value = selectedProfile.email;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);

        const tooltip = document.getElementById("myTooltip");
        tooltip.innerHTML = "Email Copied";
    };

    const emailMouseOut = () => {
        const tooltip = document.getElementById("myTooltip");
        tooltip.innerHTML = "Copy to clipboard";
    };

    const selectServiceTime = (e) => {
        updateSelectedServiceTime(e.target.value);
    };

    const selectBookingAs = (e) => {
        updateSelectedBookingAs(e.target.value);
        if (e.target.value === "1") {
            if (authData && authData.personalInfo) {
                updateBookingFor(authData.personalInfo.name);
            }
        } else {
            updateBookingFor("");
        }
        updateBookingForError("");
    };

    const openMapLocation = () => {
        const coordinates = selectedProfile.services[0].location.coordinates;
        window.open(`https://www.google.com/maps?q=${coordinates[1]},${coordinates[0]}`);
    };

    const changeDate = (date) => {
        handleDateChange(new Date(date));
        console.log("bookingDetails ", bookingDetails)
        const localBookingData = { ...bookingDetails };
        const localAvailableSlots = [];
        localBookingData.slots.forEach(each => {
            if (getFullDateFormat(new Date(each.date)) === getFullDateFormat(new Date(date))) {
                if (each.availableSlots.length) {
                    each.availableSlots.forEach(each1 => {
                        localAvailableSlots.push({
                            text: getDuration(each1.value),
                            value: JSON.stringify(each1.value)
                        });
                    });
                }

            }
        });

        updateServiceTimeHours([...localAvailableSlots]);
        if (localAvailableSlots.length) {
            updateSelectedServiceTime(localAvailableSlots[0].value);
        }
    };

    const disableRandomDates = (date) => {
        if (datePickerOpened) {
            const localBookingData = { ...bookingDetails };
            const dateArray = [];
            localBookingData.slots.forEach(each => {
                dateArray.push(getFullDateFormat(new Date(each.date)));
            });
            return !dateArray.includes(getFullDateFormat(new Date(date)));
        }
    };

    const validateBookingFor = () => {
        const localServiceError = { ...ageClassObjectError };
        let noError = true;
        if (!bookingFor.trim().length) {
            updateBookingForError(REQUIRED_ERROR);
            noError = false;
        }
        for (let item in localServiceError) {
            if (!ageClassObject[item].toString().trim().length) {
                localServiceError[item] = REQUIRED_ERROR;
                noError = false;
            } else {
                localServiceError[item] = "";
            }
        }
        updateAgeClassObjectError({ ...localServiceError });
        return noError;
    };

    const initiatePayment = (orderId, token, amount) => {
        var config = {
            "root": "",
            "flow": "DEFAULT",
            "data": {
                "orderId": orderId, /* update order id */
                "token": token, /* update token value */
                "tokenType": "TXN_TOKEN",
                "amount": amount.toString() /* update amount */
            },
            "handler": {
                "notifyMerchant": function (eventName, data) {
                    console.log("notifyMerchant handler function called");
                    console.log("eventName => ", eventName);
                    console.log("data => ", data);
                    if (eventName === "APP_CLOSED") {
                        togglePaymentPopup(true);
                    }
                }
            }
        };
        console.log("here ", config)
        if (window.Paytm && window.Paytm.CheckoutJS) {
            console.log("window.Paytm.CheckoutJS ", window.Paytm.CheckoutJS)
            //   window.Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
            // initialze configuration using init method 
            window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
                // after successfully updating configuration, invoke JS Checkout
                togglePaymentInitialization(false);
                window.Paytm.CheckoutJS.invoke();
            }).catch(function onError(error) {
                console.log("error => ", error);
                //   });
            });
        }
    }

    const payNow = async (paymentData) => {
        let requestBody = {};
        console.log("paymentData ", paymentData)
        if (!paymentData) {
            console.log("in if ")
            if (!validateBookingFor()) {
                return;
            }
            if (checkCurrentDay(bookingDetails.slotDate)) {
                if (!checkCurrentTime(JSON.parse(selectedServiceTime))) {
                    toast.error(TIME_PASSED);
                    return;
                }
            }
            requestBody = {
                bookingForName: bookingFor,
                bookingForType: Number(selectedBookingAs),
                date: new Date(new Date(new Date(new Date(new Date(getFullDateFormat(new Date(selectedDate))).setHours(5)).setMinutes(30)).setSeconds(0)).setMilliseconds(0)).toISOString(),
                slots: JSON.parse(selectedServiceTime)
            };
        } else {
            console.log("in else")
            requestBody = { ...paymentData };
        }

        console.log("requestBody ", requestBody, bookingDetails);
        togglePaymentInitialization(true);
        // initiatePayment("ORD-60a7a38ed8501ecbe8153336-1621599118809", "e4e63a8798404b13b969fd9aeaca6a821621599119840", "35.4");
        // return;

        toggleDisablePage(true);
        const bookSlotResult = await bookSlot(bookingDetails._id, requestBody);
        console.log("bookSlotResult ", bookSlotResult);
        toggleDisablePage(false);
        if (bookSlotResult.data.errorCode) {
            toast.error(API_ERROR[bookSlotResult.data.errorCode]);
            return;
        }
        if (bookSlotResult.data.data && bookSlotResult.data.data.errorCode) {
            toast.error(API_ERROR[bookSlotResult.data.data.errorCode]);
            return;
        }
        console.log("success");
        updatePopupBookingDetails({
            bookingForName: bookSlotResult.data.data.bookingForName,
            bookingForType: bookSlotResult.data.data.bookingForType,
            date: bookSlotResult.data.data.date,
            slots: bookSlotResult.data.data.slots,
            bookingId: bookSlotResult.data.data._id
        });
        const paytmToken = bookSlotResult.data.data.paytmTxnToken;
        const paytmOrderId = bookSlotResult.data.data.orderId;
        const paytmAmount = bookSlotResult.data.data.paymentDetails.total;
        initiatePayment(paytmOrderId, paytmToken, paytmAmount);
        // toast.success(API_SUCCESS.BOOKING_SUCCESSFUL);
        // props.history.push('/booking-list');
    };

    const goBack = () => {
        props.history.goBack();
    };

    const cancelPayment = () => {
        togglePaymentPopup(false);
        props.history.push("/dashboard");
    };

    const chooseGalleryImage = (index) => {
        updateCurrentImageIndex(index);
        toggleGalleryModal(true);
    };

    const modifyAgeClassObject = (text, fieldName) => {
        const localLocation = { ...ageClassObject };
        const localLocationError = { ...ageClassObjectError };
        localLocation[fieldName] = text;
        localLocationError[fieldName] = "";
        updateAgeClassObjectError({ ...localLocationError });
        updateAgeClassObject({ ...localLocation });
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className={`row dashboard ${disablePage || paymentInitialized ? "pointer-none" : ""}`} style={{ margin: 0 }}>
                {selectedProfile.name ?
                    <div className="col-lg-12">
                        <div className="col-12 categories-heading">
                            <h4 className={`mb-0`}><i className="fa fa-arrow-left" aria-hidden="true" style={{ marginRight: "10px", cursor: "pointer" }} onClick={goBack}></i>Professional's Profile</h4>
                        </div>
                        <div className="col-12 p-0">
                            <div className="row profile-details-wrapper user-profile-details-wrapper" style={{ margin: 0 }}>
                                <div className="col-12 p-0">
                                    <div className="row" style={{ margin: 0 }}>
                                        <div className="profile-image-wrapper p-0">
                                            {selectedProfile.profilePicture ?
                                                <div style={{ backgroundImage: "url(" + selectedProfile.profilePicture + ")" }} className="profile-image" /> :
                                                <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="profile-image" />}
                                            {/* {!displayEditableSlot
                                                && selectedProfile
                                                && selectedProfile.services
                                                && selectedProfile.services.length ?
                                                <button type="button" className="btn btn-primary big-book-now-button" onClick={() => bookNow(selectedProfile.services[0])}>Book Now</button> : null} */}
                                        </div>

                                        <div className="profile-other-details profile-name-wrapper">
                                            <div className="profile-name">{selectedProfile.name}</div>
                                            <div className="profile-designation">{selectedProfile.designation}</div>
                                            <div className="profile-designation languages-known">Language(s) Known: English, Hindi, Bengali</div>
                                            <div className="profile-exp profile-user-details-side-by-side bor-right pl-0">{selectedProfile.experience} Exp</div>
                                            <div className="profile-icon-wrapper profile-user-details-side-by-side bor-right pr-0">
                                                {selectedProfile.email ?
                                                    <div className="tooltip">
                                                        <i className="fa fa-envelope" aria-hidden="true" onClick={copyEmailToClipboard} onMouseLeave={emailMouseOut}>

                                                        </i>
                                                        <span className="tooltiptext" id="myTooltip">Copy to clipboard</span>
                                                    </div> : null}
                                                {selectedProfile.websiteUrl ?
                                                    <img src={require('../../../images/globe.svg')} alt="website" onClick={() => window.open(selectedProfile.websiteUrl)} /> : null}
                                                {selectedProfile && selectedProfile.services && selectedProfile.services.length ?
                                                    <img src={require('../../../images/location.svg')} alt="location" onClick={openMapLocation} /> : null}
                                            </div>
                                            <div className="profile-address-title profile-user-details-side-by-side">{selectedProfile.addressTitle}</div>
                                            <div className="profile-description-header pb-2 pt-3">
                                                Short Bio
                                            </div>
                                            <div className="profile-description-text">
                                                {selectedProfile.description}
                                            </div>
                                            <div className="mt-2">
                                                {selectedProfile && selectedProfile.designation && selectedProfile.designation.split(",").length ? selectedProfile.designation.split(",").map((cat, index) => {
                                                    return <span key={index} className="badge rounded-pill bg-primary category-pill">{cat}</span>
                                                }) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 p-0">
                                    <div className="row gallery-wrapper ml-0 mr-0">
                                        <h4 className="col-7 pl-0 pt-2">View Gallery</h4>
                                        {selectedProfile.descriptionImages && selectedProfile.descriptionImages.length ?
                                            <div className="col-5 pr-0 text-right category-arrows" onMouseEnter={() => { displayNavs(true) }}>
                                                <button className="prev-button-wrapper" onClick={prevClick} id="slideprev" disabled={!showPrevButtons}>
                                                    <i class="fas fa-arrow-left"></i>
                                                </button>
                                                <button className="next-button-wrapper" onClick={nextClick} id="slide" disabled={!showNextButtons}>
                                                    <i class="fas fa-arrow-right"></i>
                                                </button>
                                            </div> : null}
                                        <div className={`categories-wrapper pl-0 pr-0 pt-2 pb-0`} style={{ margin: 0 }}>
                                            {selectedProfile.descriptionImages && selectedProfile.descriptionImages.length ?
                                                selectedProfile.descriptionImages.map((image, index) => {
                                                    return <div className={`about-image-wrapper pl-0`} key={index} onClick={() => chooseGalleryImage(index)}>
                                                        <div className="profile-image description-image" style={{ backgroundImage: "url(" + image + ")" }} />
                                                    </div>
                                                })
                                                : <div className="text-center well col-12 color-black">
                                                No Images Available!
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="row m-0 services-list-wrapper">
                                        <h4 className="col-sm-8 col-xs-12 pl-0">{!displayEditableSlot ? 'Services offered' : 'Book Appointment'}</h4>
                                        {displayEditableSlot ?
                                        <div className="col-sm-4 col-xs-12 pr-0 text-right pt-3 back-button-wrapper">
                                            <button type="button" className="btn btn-outline-primary" onClick={() => toggleEditableSlot(false)}>Back to service list</button>
                                        </div> : null
                                        // <div className="col-sm-4 col-xs-12 pr-0 text-right pt-3 back-button-wrapper">
                                        //     <button type="button" className="btn btn-primary book-hours-button">Book Hours</button>
                                        // </div>
                                        }
                                        {!displayEditableSlot ?
                                            selectedProfile.services && selectedProfile.services.length ?
                                                selectedProfile.services.map((service, index) => {
                                                    return <div className={`col-12 professional-wrapper service-wrapper
                                                    `} key={index}>
                                                        <div className={`row ${index === selectedProfile.services.length - 1 ? 'pb-20' : ''} ${index === 0 ? 'bor-rad-top' : ''} ${index === selectedProfile.services.length - 1 ? 'bor-rad-bot' : ''}`} style={{ margin: 0 }}>
                                                            <div className="col-12">
                                                                <div className="service-list-name">{service.name} <span className="service-type">One on one</span></div>
                                                            </div>
                                                            <div className="col-12" style={{ margin: "5px 0" }}>
                                                                <div className="service-name">{service.description}</div>
                                                            </div>
                                                            <div className="col-12" style={{ margin: "0 0 5px" }}>
                                                                <div style={{ fontSize: "14px", color: "#000" }}>Location: {service.addressTitle}</div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="service-cost">Rs. {service.cost}</div>
                                                                <button type="button" className="btn btn-primary small-book-now-button" onClick={() => bookNow(service)}>Book Class</button>
                                                            </div>
                                                            {index !== selectedProfile.services.length - 1 ?
                                                                <hr style={{ width: "100%", margin: "20px 0 0" }} /> : null}
                                                        </div>
                                                    </div>
                                                })
                                                : <div className="text-center well col-12 color-black">
                                                    No Services Available!
                                            </div> :
                                            <div className="col-md-6 col-xs-12 slot-wrapper" style={{margin: "0 auto"}}>
                                                {bookingDetails.slotDate ? <div className="row m-0">
                                                    <div className="col-12 p-0">
                                                        <div className="service-list-name">{bookingDetails.name}</div>
                                                    </div>
                                                    <div className="col-12 p-0" style={{ margin: "5px 0" }}>
                                                        <div className="service-name">{bookingDetails.description}</div>
                                                    </div>
                                                    <h6 className="col-12 p-0">Fill up the following form to complete booking</h6>
                                                    <div className="col-sm-6 col-xs-12 pl-0 mb-3 mt-3">
                                                        <label for="exampleFormControlInput1" className="form-label mb-0 display-block">Select date</label>
                                                        <DatePicker value={selectedDate} onChange={changeDate} format="yyyy-MM-dd" minDate={new Date()} shouldDisableDate={disableRandomDates} onOpen={() => toggleDatepickerOpened(true)} onClose={() => toggleDatepickerOpened(false)} />
                                                    </div>
                                                    <div className="col-sm-6 col-xs-12 pl-0 mb-3 mt-3">
                                                        <label for="exampleFormControlInput1" className="form-label">Select Time</label>
                                                        {serviceTimeHours.length ?
                                                            <select className="form-select form-control" aria-label="Service Time" value={selectedServiceTime} onChange={selectServiceTime}>
                                                                {serviceTimeHours.map((loc, index) => {
                                                                    return <option key={index} value={loc.value}>{loc.text}</option>
                                                                })}
                                                            </select> : <div className="text-center well col-12 color-black pt-3">
                                                                No Slots Available!
                                                            </div>}
                                                    </div>
                                                    <div className="col-sm-6 col-xs-12 pl-0 mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label fee-label">Booking as</label>
                                                        <select className="form-select form-control" aria-label="Service Time" value={selectedBookingAs} onChange={selectBookingAs}>
                                                            {bookingAsList.map((loc, index) => {
                                                                return <option key={index} value={loc.value}>{loc.text}</option>
                                                            })}
                                                        </select>

                                                    </div>
                                                    <div className="col-sm-6 col-xs-12 pl-0 mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label fee-label">Booking for</label>
                                                        <input type="text" className={`form-control ${bookingForError ? 'error-border' : ''}`} value={bookingFor} onChange={e => { updateBookingForError(""); updateBookingFor(e.target.value) }} disabled={selectedBookingAs === "1"} />
                                                        {bookingForError ?
                                                            <div className="input-error">{bookingForError}</div> : null}
                                                    </div>
                                                    <div className="col-sm-6 col-xs-12 pl-0 mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label fee-label">Age</label>
                                                        <input type="text" className={`form-control ${ageClassObjectError.age ? 'error-border' : ''}`} value={ageClassObject.age} onChange={e => { modifyAgeClassObject(e.target.value.replace(/\D/, ''), 'age') }} />
                                                        {ageClassObjectError.age ?
                                                            <div className="input-error">{ageClassObjectError.age}</div> : null}
                                                    </div>
                                                    <div className="col-sm-6 col-xs-12 pl-0 mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label fee-label">Class</label>
                                                        <input type="text" className={`form-control ${ageClassObjectError.class ? 'error-border' : ''}`} value={ageClassObject.class} onChange={e => { modifyAgeClassObject(e.target.value.replace(/\D/, ''), 'class') }} />
                                                        {ageClassObjectError.class ?
                                                            <div className="input-error">{ageClassObjectError.class}</div> : null}
                                                    </div>
                                                    <div className="w-100 col-12 p-0 text-center mt-2">
                                                        <div className="fee-text bor-right pl-0">Fee: Rs. {bookingDetails.cost.toFixed(2)}</div>
                                                        <div className="fee-text bor-right">Convenience Fee: Rs. {bookingDetails.convenienceFee.toFixed(2)}</div>
                                                        <div className="fee-text">GST: Rs. {bookingDetails.gst.toFixed(2)}</div>
                                                    </div>
                                                    <div className="profile-amount col-12 p-0 mt-3 text-center">
                                                        Total Amount: Rs {bookingDetails.total.toFixed(2)}
                                                    </div>
                                                    {serviceTimeHours.length ? <>
                                                        <div className="col-12 booking-details-change-button pay-now-button">
                                                            <button type="button" className="btn btn-primary" onClick={!paymentInitialized ? () => payNow() : () => { }}>{paymentInitialized ? 'Initialize payment...' : 'Pay Now'}</button>
                                                        </div>
                                                        {/* <div className="col-12 text-center"><a href="https://www.parentcraftindia.com/cancellation-policies" rel="noopener noreferrer" target="_blank">Cancellation Policy</a></div> */}
                                                        </> : null}
                                                </div> : null}

                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div className="text-center well col-12 color-black pt-3">
                        loading...
                    </div>}
            </div>
            <PaymentModal displayModal={openPaymentPopup} paymentInitialized={paymentInitialized} payNow={payNow} popupBookingDetails={popupBookingDetails} cancelBooking={cancelPayment} />
            {selectedProfile.descriptionImages && openGalleryModal ?
                <GalleryModal openGalleryModal={openGalleryModal} galleryImages={selectedProfile.descriptionImages} currentImageIndex={currentImageIndex} toggleGalleryModal={toggleGalleryModal} updateCurrentImageIndex={updateCurrentImageIndex} /> : null}
        </MuiPickersUtilsProvider>
    )
};

export default injectIntl(
    connect(
        null,
        auth.actions
    )(Details)
);