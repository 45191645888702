import React, { useEffect, useState, useCallback } from 'react';
import "./dashboard.css"
import * as auth from "../../store/ducks/auth.duck";
import { getBookingDetails, cancelBook } from "../../crud/booking.crud";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { toast } from 'react-toastify';
import {
    API_ERROR,
    CALL_TIME_PASSED,
    CANCEL_TIME_PASSED
} from "../../config/error-message";
import {
    timeArrConst,
    Config
} from "../../config/constants";
import GalleryModal from "../admins/gallery-modal";
import { API_SUCCESS } from '../../config/success-message';
import CancelModal from "../../router/cancel-modal";

const defaultImage = require('../../../images/imgAvtr.png');

const timeArr = [...timeArrConst];

const Details = (props) => {

    const [selectedProfile, selectProfile] = useState({});
    const [bookingDetails, updateBookingDetails] = useState({});
    const [displayHelpModal, toggleHelpModal] = useState(false);
    const [pageInitialized, updatePageInitialization] = useState(false);
    const [disablePage, toggleDisablePage] = useState(false);
    const [openGalleryModal, toggleGalleryModal] = useState(false);
    const [currentImageIndex, updateCurrentImageIndex] = useState(-1);
    const [showPrevButtons, togglePrevButton] = useState(false);
    const [showNextButtons, toggleNextButton] = useState(true);
    const [displayCancelModal, toggleCancelModal] = useState(false);

    const handleNextPrevButtons = () => {
        if (document.getElementsByClassName("categories-wrapper")[0]) {
            if (document.getElementsByClassName("categories-wrapper")[0].clientWidth < document.getElementsByClassName("categories-wrapper")[0].scrollWidth) {
                togglePrevButton(true);
                toggleNextButton(true);

                if (document.getElementsByClassName("categories-wrapper")[0].scrollLeft === 0) {
                    togglePrevButton(false);
                }
                if (Math.round(document.getElementsByClassName("categories-wrapper")[0].scrollWidth - document.getElementsByClassName("categories-wrapper")[0].clientWidth) === Math.round(document.getElementsByClassName("categories-wrapper")[0].scrollLeft)) {
                    toggleNextButton(false);
                }

            } else {
                togglePrevButton(false);
                toggleNextButton(false);
            }
        }
    };

    const sideScroll = (element, direction, speed, distance, step) => {
        let scrollAmount = 0;
        let slideTimer = setInterval(function () {
            if (direction === 'left') {
                element.scrollLeft -= step;
            } else {
                element.scrollLeft += step;
            }
            scrollAmount += step;
            if (scrollAmount >= distance) {
                handleNextPrevButtons();
                window.clearInterval(slideTimer);
            }
        }, speed);
    }

    const nextClick = () => {
        var container = document.getElementsByClassName('categories-wrapper')[0];
        sideScroll(container, 'right', 25, 100, 10);
    };

    const prevClick = () => {
        var container = document.getElementsByClassName('categories-wrapper')[0];
        sideScroll(container, 'left', 25, 100, 10);
    };

    window.onresize = () => {
        handleNextPrevButtons();
    };

    window.onload = () => {
        handleNextPrevButtons();
    };

    const displayNavs = useCallback((bool) => {
        if (bool) {
            handleNextPrevButtons();
        }
    }, []);

    const getFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const month = (date.getMonth() + 1).toString().length === 1 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const getTime = (timeStr, date) => {
        const time = timeStr.slice(0, timeStr.length - 3)
        const amPm = timeStr.slice(timeStr.length - 2)
        // console.log("time ", amPm, time)
        let d = new Date();
        if (date) {
            d = new Date(date);
        }
        if (amPm === "AM" && time.split(":")[0] === "12") {
            return new Date(new Date(new Date(new Date(d.setHours(0)).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
        }
        if (amPm === "PM" && time.split(":")[0] !== "12") {
            return new Date(new Date(new Date(new Date(d.setHours(Number(time.split(":")[0]) + 12)).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
        }
        return new Date(new Date(new Date(new Date(d.setHours(time.split(":")[0])).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
    };

    const getDuration = (times) => {
        times.sort((a, b) => { return a - b });
        const startTime = timeArr[times[0] - 1];
        const endTime = timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0];
        return `${startTime} - ${endTime}`;
    };

    const checkCurrentDay = (date) => {
        const today = new Date(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0)).getTime();
        const day = new Date(new Date(new Date(new Date(new Date(date).setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0)).getTime();
        // console.log("today ", today, day)
        return today === day;
    };

    const checkJoiningCallTime = useCallback((localBookingData) => {
        console.log("hereeeeeeeeee")
        if (checkCurrentDay(localBookingData.slotDate)) {
            const today = new Date().getTime();
            const startTime = getTime(timeArr[localBookingData.slots[0] - 1]);
            const endTime = getTime(timeArr[localBookingData.slots[localBookingData.slots.length - 1]]);
            console.log("start-------> ", startTime, endTime, today)
            return (startTime < today && endTime > today) && localBookingData.status === Config.Booking.Status.Confirmed;
        }
        return false;
    }, []);

    const checkCancelTime = useCallback((localBookingData) => {
        const today = new Date().getTime();
        const startTime = getTime(timeArr[localBookingData.slots[0] - 1], localBookingData.slotDate);
        const lessThirtyStartTime = new Date(startTime).setMinutes(new Date(startTime).getMinutes() - 30);
        console.log("start-------> ", lessThirtyStartTime, today)
        return (lessThirtyStartTime > today) && localBookingData.status === Config.Booking.Status.Confirmed;
    }, []);

    const getBookingData = useCallback(async (id) => {
        toggleDisablePage(true);
        const bookingResult = await getBookingDetails(id);
        toggleDisablePage(false);
        if (bookingResult.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.errorCode]);
            return;
        }
        if (bookingResult.data.data && bookingResult.data.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.data.errorCode]);
            return;
        }
        console.log("bookingResult ", bookingResult);
        // return;
        const resultData = bookingResult.data.data;

        const localProfessionalObj = {
            name: resultData.vendorRef.personalInfo.name,
            profilePicture: resultData.vendorRef.personalInfo.profilePicture,
            description: resultData.vendorRef.personalInfo.description,
            designation: "",
            bookingId: resultData._id,
            experience: "",
            email: resultData.vendorRef.personalInfo.email,
            websiteUrl: resultData.vendorRef.personalInfo.website,
            addressTitle: resultData.serviceRef.title,
            rating: 5, // needs to be updated with response data
            descriptionImages: [],
            services: [{
                location: resultData.serviceRef.location
            }],
            cost: resultData.paymentDetails.serviceFee,
            convenienceFee: resultData.paymentDetails.convenienceFee,
            refundAmount: resultData.paymentDetails.refundAmount ? resultData.paymentDetails.refundAmount : 0,
            gst: resultData.paymentDetails.gst,
            total: resultData.paymentDetails.total,
            slots: resultData.slots,
            status: resultData.status
        };
        localProfessionalObj.slotDuration = getDuration(resultData.slots);
        localProfessionalObj.slotDate = getFullDateFormat(new Date(resultData.date));
        localProfessionalObj.appointmentNumber = resultData.pin;
        if (resultData.vendorRef.personalInfo.experience.year || resultData.vendorRef.personalInfo.experience.month) {
            const year = resultData.vendorRef.personalInfo.experience.year;
            const month = resultData.vendorRef.personalInfo.experience.month;
            if (year) {
                localProfessionalObj.experience += `${year} year${year > 1 ? 's' : ''}`;
            }
            if (month) {
                localProfessionalObj.experience += ` ${month} month${month > 1 ? 's' : ''}`;
            }
        }
        if (resultData.vendorRef.photos && resultData.vendorRef.photos.length) {
            resultData.vendorRef.photos.forEach(each => {
                localProfessionalObj.descriptionImages.push(each.url);
            });
        }
        if (resultData.vendorRef.personalInfo.categories && resultData.vendorRef.personalInfo.categories.length) {
            resultData.vendorRef.personalInfo.categories.forEach((item, index) => {
                localProfessionalObj.designation += item.title;
                if (index !== resultData.vendorRef.personalInfo.categories.length - 1) {
                    localProfessionalObj.designation += ", ";
                }
            });
        }
        localProfessionalObj.isCancelable = checkCancelTime(localProfessionalObj);
        localProfessionalObj.isCallJoinable = checkJoiningCallTime(localProfessionalObj);
        console.log("localProfessionalObj ", localProfessionalObj);

        selectProfile({ ...localProfessionalObj });
        localProfessionalObj.name = resultData.serviceRef ? resultData.serviceRef.title : "";
        localProfessionalObj.description = resultData.serviceRef ? resultData.serviceRef.description : "";
        updateBookingDetails({ ...localProfessionalObj });
        setTimeout(() => {
            displayNavs(true);
        }, 1000);
    }, [checkCancelTime, checkJoiningCallTime, displayNavs]);

    useEffect(() => {
        if (!pageInitialized) {
            updatePageInitialization(true);
            if (props.match.params.bookingId) {
                getBookingData(props.match.params.bookingId);
            }
        }
    }, [props.match.params, pageInitialized, getBookingData]);

    const copyEmailToClipboard = () => {
        const tempInput = document.createElement("input");
        tempInput.value = selectedProfile.email;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);

        const tooltip = document.getElementById("myTooltip");
        tooltip.innerHTML = "Email Copied";
    };

    const emailMouseOut = () => {
        const tooltip = document.getElementById("myTooltip");
        tooltip.innerHTML = "Copy to clipboard";
    };

    const openMapLocation = () => {
        const coordinates = selectedProfile.services[0].location.coordinates;
        window.open(`https://www.google.com/maps?q=${coordinates[1]},${coordinates[0]}`);
    };

    const joinTwilioCall = () => {
        if (checkJoiningCallTime(bookingDetails)) {
            props.history.push(`/video-call/${bookingDetails.bookingId}`);
        } else {
            toast.error(CALL_TIME_PASSED);
        }
    };

    const cancelBooking = async () => {
        if (checkCancelTime(bookingDetails)) {
            console.log("bookingDetails.bookingId ",bookingDetails)
            // api call
            // return;
            toggleDisablePage(true);
            const cancelBookingResult = await cancelBook(bookingDetails.bookingId);
            toggleDisablePage(false);
            if (cancelBookingResult.data.errorCode) {
                toast.error(API_ERROR[cancelBookingResult.data.errorCode]);
                return;
            }
            if (cancelBookingResult.data.data && cancelBookingResult.data.data.errorCode) {
                toast.error(API_ERROR[cancelBookingResult.data.data.errorCode]);
                return;
            }
            console.log("cancelBookingResult ", cancelBookingResult);
            toast.success(API_SUCCESS.CANCEL_SUCCESS);
            getBookingData(bookingDetails.bookingId);
            toggleCancelModal(false);
            // return;
            // const resultData = bookingResult.data.data;
        } else {
            toast.error(CANCEL_TIME_PASSED);
        }
    };

    const goBack = () => {
        props.history.push("/booking-list");
    };

    const chooseGalleryImage = (index) => {
        updateCurrentImageIndex(index);
        toggleGalleryModal(true);
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className={`row dashboard ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
                <CancelModal displayCancelModal={displayCancelModal}
                toggleCancelModal={toggleCancelModal} cancelBooking={cancelBooking} />
                {selectedProfile.name ?
                    <div className="col-lg-12">
                        <div className="col-12 categories-heading">
                            <h4 className={`mb-0`}><i className="fa fa-arrow-left" aria-hidden="true" style={{ marginRight: "10px", cursor: "pointer" }} onClick={goBack}></i>Booking Details</h4>
                        </div>
                        <div className="col-12">
                            <div className="row profile-details-wrapper" style={{ margin: 0 }}>
                                <div className="col-12 p-0">
                                    <div className="row" style={{ margin: 0 }}>
                                        <div className="col-md-5 col-xs-12 pr-20 left-booking-item">
                                            <div className="row m-0">
                                                <div className="profile-image-wrapper p-0">
                                                    {selectedProfile.profilePicture ?
                                                        <div style={{ backgroundImage: "url(" + selectedProfile.profilePicture + ")" }} className="profile-image" /> :
                                                        <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="profile-image" />}
                                                </div>
                                                <div className="profile-other-details profile-name-wrapper">
                                                    <div className="profile-name">{selectedProfile.name}</div>
                                                    <div className="profile-designation">{selectedProfile.designation}</div>
                                                    <div className="profile-address-title">{selectedProfile.addressTitle}</div>
                                                    <div className="profile-icon-wrapper">
                                                        {selectedProfile.email ?
                                                            <div className="tooltip">
                                                                <i className="fa fa-envelope" aria-hidden="true" onClick={copyEmailToClipboard} onMouseLeave={emailMouseOut}>

                                                                </i>
                                                                <span className="tooltiptext" id="myTooltip">Copy to clipboard</span>
                                                            </div> : null}
                                                        {selectedProfile.websiteUrl ?
                                                            <img src={require('../../../images/globe.svg')} alt="website" onClick={() => window.open(selectedProfile.websiteUrl)} /> : null}
                                                        {selectedProfile && selectedProfile.services && selectedProfile.services.length ?
                                                            <img src={require('../../../images/location.svg')} alt="location" onClick={openMapLocation} /> : null}
                                                    </div>
                                                    <div className="profile-exp">{selectedProfile.experience} Exp</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-7 col-xs-12 pl-20 pr-0 booking-gallery">
                                            <div className="row m-0">
                                                <div className="col-7 pl-0 profile-name">View Gallery</div>
                                                {selectedProfile.descriptionImages && selectedProfile.descriptionImages.length ?
                                                    <div className="col-5 pr-0 text-right category-arrows" onMouseEnter={() => { displayNavs(true) }}>
                                                        <button className="prev-button-wrapper" onClick={prevClick} id="slideprev" disabled={!showPrevButtons}>
                                                            <i class="fas fa-arrow-left"></i>
                                                        </button>
                                                        <button className="next-button-wrapper" onClick={nextClick} id="slide" disabled={!showNextButtons}>
                                                            <i class="fas fa-arrow-right"></i>
                                                        </button>
                                                    </div> : null}
                                                <div className={`categories-wrapper pl-0 pr-0 pt-2 pb-0`} style={{ margin: 0 }}>
                                                    {selectedProfile.descriptionImages && selectedProfile.descriptionImages.length ?
                                                        selectedProfile.descriptionImages.map((image, index) => {
                                                            return <div className={`about-image-wrapper pl-0`} key={index} onClick={() => chooseGalleryImage(index)}>
                                                                <div className="profile-image description-image" style={{ backgroundImage: "url(" + image + ")" }} />
                                                            </div>
                                                        })
                                                        : <div className="text-center well col-12 color-black">
                                                            No Images Available!
                                                                </div>}
                                                </div>
                                            </div>
                                        </div>



                                        {/* <div className="profile-other-details profile-name-wrapper">
                                            <div className="col-12 pr-0">
                                                <div className="row m-0">
                                                    
                                                    
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-12 p-0">
                                    {Object.keys(bookingDetails).length ?
                                        <div className="row" style={{ margin: 0 }}>
                                            <div className="col-12 pl-0 pr-0 service-details-wrapper">
                                                <div className="profile-name">{bookingDetails.name}</div>
                                                <div className="profile-service-desc">{bookingDetails.description}</div>
                                                <div className="profile-date-time row ml-0 mr-0 mb-3">
                                                    <div className="col-md-4 col-6 pl-0">
                                                        <div className="label">Selected date</div>
                                                        <div><img src={require('../../../images/calendar.png')} alt="calendar" style={{ width: "17px", marginRight: "3px" }} /> {bookingDetails.slotDate}</div>
                                                    </div>
                                                    <div className="col-md-4 col-6 pl-0">
                                                        <div className="label">Time slot</div>
                                                        <div><img src={require('../../../images/clock.svg')} style={{ width: "17px", marginRight: "3px" }} alt="clock" /> {bookingDetails.slotDuration}</div>
                                                    </div>
                                                </div>
                                                <div className="w-100">
                                                    <div className="fee-text bor-right pl-0">Fee: Rs. {bookingDetails.cost.toFixed(2)}</div>
                                                    <div className="fee-text bor-right">Convenience Fee: Rs. {bookingDetails.convenienceFee.toFixed(2)}</div>
                                                    <div className="fee-text bor-right">GST: Rs. {bookingDetails.gst.toFixed(2)}</div>
                                                    <div className="fee-text">Total: Rs. {bookingDetails.total.toFixed(2)}</div>
                                                </div>
                                                <div className="profile-amount row ml-0 mr-0">
                                                    Status: {Config.Booking.NumberStatus[selectedProfile.status]}
                                                </div>
                                            </div>
                                            <div className="col-12 pl-0 pr-0 pt-20">
                                                <div className="row text-center" style={{ margin: "0 0 15px 0" }}>
                                                {selectedProfile.status === Config.Booking.Status.Confirmed ? <>
                                                    <div className="col-12 p-0 booking-details-header">
                                                        Appointment Number: {bookingDetails.appointmentNumber}
                                                    </div>
                                                    <div className="col-12 p-0 booking-details-header mt-2">
                                                        <span className="call-phone-icon-wrapper">
                                                            <i class="fa fa-phone" aria-hidden="true"></i>
                                                        </span>Call Now
                                                    </div>
                                                    <div className="col-12 booking-details-change-button  appointment-details-button-wrapper">
                                                        {bookingDetails.isCallJoinable ?
                                                            <button type="button" className="btn btn-primary join-call" onClick={joinTwilioCall}>Join Class</button> : null}
                                                        {bookingDetails.isCancelable ?
                                                            <>
                                                                <button type="button" className="btn btn-outline-primary" onClick={() => toggleCancelModal(true)}>Cancel</button>
                                                                <div className="pt-2" style={{ fontSize: "12px" }}>*Cancel Before 30 Minutes</div>
                                                            </> : null}
                                                    </div> </> : null}
                                                    {selectedProfile.status === Config.Booking.Status.Completed ?
                                                    <div className="col-12 p-0 booking-details-header">
                                                        The appointment has been completed
                                                    </div> : null}
                                                    {selectedProfile.status === Config.Booking.Status.Failed ?
                                                    <div className="col-12 p-0 booking-details-header">
                                                        The appointment has been failed
                                                    </div> : null}
                                                    {selectedProfile.status === Config.Booking.Status.CanceledByCustomer ?
                                                    <div className="col-12 p-0 booking-details-header">
                                                        The appointment has been cancelled by you
                                                        <div className="cancel-info">
                                                            You are eligible for a refund of Rs {selectedProfile.refundAmount.toFixed(2)}
                                                        </div>
                                                        <div className="cancel-info">
                                                            The amount will be refunded in 7 working days.
                                                        </div>
                                                    </div> : null}
                                                    {selectedProfile.status === Config.Booking.Status.CanceledByVendor ?
                                                    <div className="col-12 p-0 booking-details-header">
                                                        The appointment has been cancelled by the professional
                                                        <div className="cancel-info">
                                                            You are eligible for a refund of Rs {selectedProfile.refundAmount.toFixed(2)}
                                                        </div>
                                                        <div className="cancel-info">
                                                            The amount will be refunded in 7 working days.
                                                        </div>
                                                    </div> : null}
                                                    {selectedProfile.status === Config.Booking.Status.Timedout ?
                                                    <div className="col-12 p-0 booking-details-header">
                                                        The appointment has been timed out
                                                        <div className="cancel-info">
                                                            You are eligible for a refund of Rs {selectedProfile.refundAmount.toFixed(2)}
                                                        </div>
                                                        <div className="cancel-info">
                                                            The amount will be refunded in 7 working days.
                                                        </div>
                                                    </div> : null}
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div className="text-center well col-12 color-black pt-3">
                        loading...
                    </div>}
                <div className={`modal login-modal ask-question-modal review-modal fade ${displayHelpModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Rating & Review</h5>
                            </div>
                            <div className="modal-body">
                                <div className="row review-profile-details">
                                    <div className="profile-photo">
                                        {selectedProfile.profilePicture ?
                                            <div style={{ backgroundImage: "url(" + selectedProfile.profilePicture + ")" }} className="background-image-premium" /> :
                                            <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-premium" />}
                                        {/* <div style={{backgroundImage: "url(" + selectedProfile.profilePicture + ")"}} className="background-image-premium"></div> */}
                                    </div>
                                    <div className="profile-other-details">
                                        <div className="professional-name">{selectedProfile.name}</div>
                                        <div className="professional-designation">{selectedProfile.designation}</div>
                                    </div>
                                </div>
                                <div className="review-label">
                                    How would you rate our service?
                    </div>
                                <div>
                                    <div className="profile-rating-wrapper">
                                        <img src={require('../../../images/favourite2_Fill.svg')} alt="rating 1" />
                                        <img src={require('../../../images/favourite2_Fill.svg')} alt="rating 2" />
                                        <img src={require('../../../images/favourite2_Fill.svg')} alt="rating 3" />
                                        <img src={require('../../../images/favourite2_Fill.svg')} alt="rating 4" />
                                        <img src={require('../../../images/favourite2_Fill.svg')} alt="rating 5" />
                                    </div>
                                </div>
                                <div>
                                    <div className="form-group question-input">
                                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="4" placeholder="Type your review ..."></textarea>
                                    </div>
                                </div>

                                <div className="col-12 login-button">
                                    <button type="button" className="btn btn-primary">Submit</button>
                                    <button type="button" className="btn btn-link" onClick={() => toggleHelpModal(false)}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {selectedProfile.descriptionImages && openGalleryModal ?
                <GalleryModal openGalleryModal={openGalleryModal} galleryImages={selectedProfile.descriptionImages} currentImageIndex={currentImageIndex} toggleGalleryModal={toggleGalleryModal} updateCurrentImageIndex={updateCurrentImageIndex} /> : null}
        </MuiPickersUtilsProvider>
    )
};

export default injectIntl(
    connect(
        null,
        auth.actions
    )(Details)
);