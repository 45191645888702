import React, { useEffect, useState, useCallback } from 'react';
import "./forum.css";
import AskQuestionModal from './ask-question-modal';
import { getQAList, getForumDetails, addAnswer } from "../../crud/qa-forum.crud";
import { shallowEqual, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import {
  API_ERROR
} from "../../config/error-message";

const qaStatus = {
  1: "Pending",
  2: "Approved",
  3: "Rejected"
};

const monthArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const defaultImage = require('../../../images/imgAvtr.png');
const adminProfileImage = require('../../../images/logo-blue.png');

const MyQA = (props) => {
  const [qaList, updateQA] = useState([]);
  const [myAnswer, updateMyAnswer] = useState("");
  const [displayModal, toggleModal] = useState(false);
  const [selectedQuestionId, updateSelectedQuestionId] = useState("");
  const [disablePage, toggleDisablePage] = useState(false);
  const [loadingQuestions, toggleLoadingQuestions] = useState(true);
  const { authData } = useSelector(
    ({ auth }) => ({
      authData: auth.user
    }),
    shallowEqual
  );

  const getFullDate = (date) => {
    const d = new Date(date);
    return d.getDate() + ' ' + monthArr[d.getMonth()] + ' ' + d.getFullYear();
  };

  const openQuestionModal = () => {
    toggleModal(true);
  };

  const getAllQaList = useCallback(async () => {
    toggleDisablePage(true);
    const qaResults = await getQAList();
    toggleDisablePage(false);
    toggleLoadingQuestions(false);
    if (qaResults.data.errorCode) {
      toast.error(API_ERROR[qaResults.data.errorCode]);
      return;
    }
    if (qaResults.data.data && qaResults.data.data.errorCode) {
      toast.error(API_ERROR[qaResults.data.data.errorCode]);
      return;
    }
    console.log("qaResults ", qaResults, authData)
    if (qaResults.data.data && qaResults.data.data.data && qaResults.data.data.data.length) {
      let qaObj = {};
      const qaArr = [];
      qaResults.data.data.data.forEach(each => {
        qaObj = {};
        qaObj._id = each._id;
        qaObj.questionText = each.question;
        qaObj.selected = false;
        qaObj.questionedByMe = false;
        qaObj.answerByMe = false;
        qaObj.date = getFullDate(each.createdAt);
        qaObj.status = Number(each.status); // status need from response
        if (each.customerId &&
          authData &&
          each.customerId === authData._id) {
          qaObj.questionedByMe = true;
        }
        if (!qaObj.questionedByMe) {
          qaObj.answerByMe = true;
        }
        qaObj.answers = [];
        qaArr.push({ ...qaObj });
      });
      updateQA([...qaArr]);
    }
  }, [authData]);

  useEffect(() => {
    getAllQaList();
  }, [getAllQaList]);

  const selectAnswer = (id) => {
    const localProf = [...qaList];
    localProf.forEach(each => {
      if (each._id === id) {
        if (selectedQuestionId === each._id) {
          each.selected = true;
        } else {
          each.selected = !each.selected;
        }

      } else {
        each.selected = false;
      }

    });
    updateQA([...localProf]);
  };

  const getQuestionDetails = async (id) => {
    toggleDisablePage(true);
    const localQuestionDetails = await getForumDetails(id);
    toggleDisablePage(false);
    if (localQuestionDetails.data.errorCode) {
      toast.error(API_ERROR[localQuestionDetails.data.errorCode]);
      return;
    }
    if (localQuestionDetails.data.data && localQuestionDetails.data.data.errorCode) {
      toast.error(API_ERROR[localQuestionDetails.data.data.errorCode]);
      return;
    }
    console.log("localQuestionDetails ", localQuestionDetails.data.data);
    const localProf = [...qaList];
    localProf.forEach(each => {
      if (each._id === id) {
        let answerObj = {};
        if (localQuestionDetails.data.data && localQuestionDetails.data.data.answers && localQuestionDetails.data.data.answers.length) {
          each.answers = [];
          each.askedByName = localQuestionDetails.data.data.askedBy.personalInfo.name || '';
          each.askedByProfilePhoto = localQuestionDetails.data.data.askedBy.personalInfo.profilePicture || '';
          localQuestionDetails.data.data.answers.forEach(item => {
            answerObj = {};
            answerObj.answerText = item.answer;
            answerObj._id = item._id;
            answerObj.date = item.time;
            answerObj.name = "";
            answerObj.profilePicture = "";
            if (item.answeredBy && item.answeredBy.personalInfo) {
              answerObj.name = item.answeredBy.personalInfo.name;
              answerObj.profilePicture = item.answeredBy.personalInfo.profilePicture;
              answerObj.userType = item.answeredBy.userType;
            }
            console.log("answerObj ", answerObj)
            each.answers.push({ ...answerObj });
            each.noOfComments = each.answers.length;
          })
        }
        console.log("each ", each, id)
      }
    });
    console.log("localProf ", localProf)
    updateQA([...localProf]);
    selectAnswer(id);
  };

  const chooseAnswer = (item) => {
    if (!item.selected) {
      getQuestionDetails(item._id);
      updateSelectedQuestionId(item._id);
    } else {
      updateSelectedQuestionId("");
      const localProf = [...qaList];
      localProf.forEach(each => {
        if (each._id === item._id) {
          each.selected = false;
        }

      });
      updateQA([...localProf]);
    }
  };

  const giveAnswer = async () => {
    console.log("myAnswer ", myAnswer);
    if (myAnswer.trim().length) {
      toggleDisablePage(true);
      const answerResult = await addAnswer(selectedQuestionId, { answer: myAnswer.trim() });
      toggleDisablePage(false);
      if (answerResult.data.errorCode) {
        toast.error(API_ERROR[answerResult.data.errorCode]);
        return;
      }
      if (answerResult.data.data && answerResult.data.data.errorCode) {
        toast.error(API_ERROR[answerResult.data.data.errorCode]);
        return;
      }

      updateMyAnswer("");
      getQuestionDetails(selectedQuestionId);
    }
  };

  return (
    <div className={`row forum ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
      <div className="col-lg-12 pad-0-below-500">
        <div className="row" style={{ margin: 0 }}>
          <div className={`col-md-7 col-xs-12 categories-heading`}>
            <h4 className={`mb-0 page-heading`}>My Q & A</h4>
            <button type="button" className="btn btn-link pt-0" onClick={() => props.history.push("/problem-area")}>Back to Problem Area</button>
          </div>
          <div className={`col-md-5 col-xs-12 text-right language-dropdown-wrapper`}>
            <button type="button" className="btn btn-outline-primary" onClick={() => props.history.push("/forum")}>Forum</button>
            <button type="button" className="btn btn-outline-default ml-3" onClick={openQuestionModal}>Ask Question</button>
          </div>
        </div>
        <div className="col-12">
          <div className="row" style={{ margin: 0 }}>
            {qaList.length ?
              qaList.map((professional, index) => {
                return <div className={`col-12 professional-wrapper ${professional.selected ? 'professional-wrapper-selected' : ''}
                                `} key={index}>
                  <div className="row professional-wrapper-row p-0" style={{ margin: 0 }}>
                    <div className="col-12 p-0">
                      <div className={`professional-designation row m-0 ${!professional.selected ? 'professional-designation-not-selected' : 'professional-designation-selected'}`} onClick={professional.status === 2 ? () => chooseAnswer({ ...professional }) : () => { }}>
                        <div className="col-12 pl-0 pr-0 pb-1">
                          <div className="row m-0">
                            <div className="col-sm-9 col-xs-12 pl-0">
                              <div className="qa-date">{professional.date}</div>
                              <div className="qa-by-you">
                                {professional.answerByMe ? "Answered by you" : ""}
                                {professional.questionedByMe ? "Asked by you" : ""}
                              </div>
                            </div>
                            <div className={`col-sm-3 col-xs-12 text-right pt-2 status-text ${professional.status === 2 ? 'confirmed-status' : ''}`}>
                              {qaStatus[professional.status]}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 p-0">
                          {professional.selected ?
                            professional.askedByProfilePhoto ?
                              <div style={{ backgroundImage: "url(" + professional.askedByProfilePhoto + ")" }} className="background-image-professional mb-0" /> :
                              <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-professional mb-0" /> : null}
                              {!professional.selected ?
                                professional.questionText :
                                <div className="question-asked-details">
                                <div className="question-commentedby">{professional.askedByName}</div>
                                {professional.questionText}
                            </div>}
                        </div>
                      </div>
                      {!professional.selected ?
                        null :
                        <div className="professional-location answer">
                          <div className="row answers-wrapper" style={{ margin: 0 }}>
                            <div className="col-12 p-0">
                              {professional.answers.length ?
                                professional.answers.map((answer, index) => {
                                  return <div className="row" style={{ margin: 0 }} key={index}>
                                    {answer.userType !== 3 ?
                                      answer.profilePicture ?
                                        <div style={{ backgroundImage: "url(" + answer.profilePicture + ")" }} className="background-image-professional" /> :
                                        <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-professional" /> :
                                      <div style={{ backgroundImage: "url(" + adminProfileImage + ")" }} className="background-image-professional" />}
                                    <div className=" answer-text">
                                      <div className="answer-commentedby">{answer.userType !== 3 ? answer.name : 'Parentcraft Admin'}
                                        <span className="answer-date"> {getFullDate(answer.date)} </span>
                                      </div>
                                      <div className="answer-text-span-wrapper">{answer.answerText.split('\n').map((item, key) => {
                                        return <span key={key}>{item}<br /></span>
                                      })}</div>
                                    </div>
                                  </div>
                                })
                                : null}
                            </div>
                          </div>
                          <div className="row" style={{ margin: 0 }}>
                            {authData && authData.personalInfo && authData.personalInfo.profilePicture ?
                              <div style={{ backgroundImage: "url(" + authData.personalInfo.profilePicture + ")" }} className="background-image-professional" /> :
                              <div style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-professional" />}
                            <div className="message-input">
                              <textarea className="form-control textarea" placeholder="Add your comment" value={myAnswer} onChange={(e) => updateMyAnswer(e.target.value)} rows="2"></textarea>
                              <button className="btn btn-outline-secondary" type="button" id="button-addon2" disabled={!myAnswer.trim().length} onClick={giveAnswer}><img src={require("../../../images/message-send.svg")} alt="send message" /></button>
                            </div>
                          </div>
                        </div>}
                    </div>
                  </div>
                </div>
              })
              : <div className="text-center well col-12 color-black">
                {!loadingQuestions ? 'No Questions Available!' : 'loading...'}
              </div>}
          </div>
        </div>
      </div>
      <AskQuestionModal displayModal={displayModal} toggleModal={toggleModal} getAllForumList={getAllQaList} />
    </div>
  )
}

export default MyQA;