import React, { useState, useCallback, useEffect } from "react";
import Video from "twilio-video";
import './App.css';
// import Lobby from "./Lobby";
import Room from "./Room";
import {joinCall} from "../../crud/booking.crud";
import { shallowEqual, useSelector } from "react-redux";
import { getBookingDetails } from "../../crud/booking.crud";
import {
  API_ERROR
} from "../../config/error-message";
import { toast } from 'react-toastify';

const VideoChat = (props) => {
  // const [username, setUsername] = useState("");
  const [roomName, setRoomName] = useState("");
  const [room, setRoom] = useState(null);
  const [connecting, setConnecting] = useState(false);
  const [pageInitialized, updatePageInitialization] = useState(false);
  const [bookingDetails, updateBookingDetails] = useState({});
  const [disablePage, toggleDisablePage] = useState(false);
  const [bookingId, updateBookingId] = useState("");
  const { authData } = useSelector(
    ({ auth }) => ({
        authData: auth.user
    }),
    shallowEqual
  );

  const fetchTokenAndInitializeCall = useCallback(async (bookingId) => {
    if (!connecting) {
      const twilioResult = await joinCall({
        identity: authData._id,
        room: bookingId,
      });
      if (twilioResult.data.data && twilioResult.data.data.token) {
        Video.connect(twilioResult.data.data.token, {
          name: bookingId,
        })
          .then((room) => {
            setConnecting(true);
            setRoom(room);
            setRoomName(bookingId);
          })
          .catch((err) => {
            console.error(err);
            setConnecting(true);
          });
      }
    }
  },[authData._id, connecting]);

  const handleLogout = useCallback(() => {
    setRoom((prevRoom) => {
      if (prevRoom) {
        prevRoom.localParticipant.tracks.forEach((trackPub) => {
          trackPub.track.stop();
        });
        prevRoom.disconnect();
      }
      props.history.push(`/booking-details/${bookingId}`);
      return null;
    });
  }, [bookingId, props.history]);

  const handleComponentOut = useCallback(() => {
    setRoom((prevRoom) => {
      if (prevRoom) {
        prevRoom.localParticipant.tracks.forEach((trackPub) => {
          trackPub.track.stop();
        });
        prevRoom.disconnect();
      }
      return null;
    });
  }, []);

  const getBookingData = useCallback(async (id) => {
        toggleDisablePage(true);
        const bookingResult = await getBookingDetails(id);
        toggleDisablePage(false);
        if (bookingResult.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.errorCode]);
            return;
        }
        if (bookingResult.data.data && bookingResult.data.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.data.errorCode]);
            return;
        }
        console.log("bookingResult ", bookingResult);
        // return;
        const resultData = bookingResult.data.data;

        const localProfessionalObj = {
        };
        localProfessionalObj.appointmentNumber = resultData.pin;
        console.log("localProfessionalObj ", localProfessionalObj);

        updateBookingDetails({ ...localProfessionalObj });
        
    }, []);

  useEffect(() => {
    if (!pageInitialized) {
      updatePageInitialization(true);
      if (props.match.params.bookingId) {
        fetchTokenAndInitializeCall(props.match.params.bookingId);
        updateBookingId(props.match.params.bookingId);
        getBookingData(props.match.params.bookingId);
      }
  }
  }, [props.match.params, pageInitialized, fetchTokenAndInitializeCall, getBookingData]);

  useEffect(() => {
    if (room) {
      const tidyUp = (event) => {
        if (event.persisted) {
          return;
        }
        if (room) {
          handleLogout();
        }
      };
      window.addEventListener("pagehide", tidyUp);
      window.addEventListener("beforeunload", tidyUp);
      return () => {
        window.removeEventListener("pagehide", tidyUp);
        window.removeEventListener("beforeunload", tidyUp);
      };
    }
  }, [room, handleLogout]);

  let render;
  if (room) {
    render = (
      <div className={`video-call-wrapper ${disablePage ? 'pointer-none': ''}`}>
        <main>
          <Room roomName={roomName} room={room} handleLogout={handleLogout} handleComponentOut={handleComponentOut} bookingDetails={bookingDetails} />
        </main>
      </div>
    );
  } else {
    render = (
      <div className="text-center well col-12 color-black pt-3">
        loading...
      </div>
    );
  }
  return render;
};

export default VideoChat;
