import axios from "./axios.interceptor";
import baseUrl from "./api.util";

export const GET_BOOKINGS = `${baseUrl}/account/customer/booking`;
export const JOIN_CALL = `${baseUrl}/account/customer/booking/video/token`;

const localDate = new Date(new Date(new Date(new Date(new Date().setHours(5)).setMinutes(30)).setSeconds(0)).setMilliseconds(0)).toISOString();

export const getUpcomingBookings = async () => {
  const res = await axios.post(`${GET_BOOKINGS}/upcoming?skip=0&limit=0`,{todaysDate: localDate});
  return res;
}

export const getPastBookings = async () => {
  const res = await axios.post(`${GET_BOOKINGS}/past?skip=0&limit=0`, {todaysDate: localDate});
  return res;
}

export const getBookingDetails = async (id) => {
  const res = await axios.get(`${GET_BOOKINGS}/details/${id}`);
  return res;
}

export const joinCall = async (requestBody) => {
  const res = await axios.post(`${JOIN_CALL}`, requestBody);
  return res;
}

export const cancelBook = async (id) => {
  console.log("id ", id)
  const res = await axios.put(`${GET_BOOKINGS}/cancel/${id}`);
  return res;
}

