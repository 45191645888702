import React, { useEffect, useState, useCallback } from 'react';
import "./dashboard.css"
import * as auth from "../../store/ducks/auth.duck";
import { getProfessionalCategories, getNearbyProfessionals } from "../../crud/home.crud";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { toast } from 'react-toastify';
import {
    API_ERROR
} from "../../config/error-message";

const defaultImage = require('../../../images/imgAvtr.png');
// const dummyProfessionals = [{
//     name: "Dr. Madhu Roberts",
//     designation: "Career Counsellor",
//     location: "Bangalore, Karnataka",
//     experience: "9",
//     rating: 5,
//     profilePicture: require('../../../images/Man.jpg')
// },{
//     name: "Dr. Madhumita Roberts",
//     designation: "Career Counsellor",
//     location: "Bangalore, Karnataka",
//     experience: "9",
//     rating: 5,
//     profilePicture: require('../../../images/Man.jpg')
// },{
//     name: "Dr. Madhu Roberts",
//     designation: "Career Counsellor",
//     location: "Bangalore, Karnataka",
//     experience: "9",
//     rating: 5,
//     profilePicture: require('../../../images/Man.jpg')
// }];

const Dashboard = (props) => {

    const [categories, updateCategory] = useState([]);
    const [professionals, updateProfessional] = useState([]);
    const [sortDown, toggleSort] = useState(false);
    const [showPrevButtons, togglePrevButton] = useState(false);
    const [showNextButtons, toggleNextButton] = useState(true);
    const [selectedCategory, updateSelectedCategory] = useState("");
    const [userLocation, updateUserLocation] = useState([]);
    const [pageInitialized, updatePageInitialization] = useState(false);
    const [disablePage, toggleDisablePage] = useState(false);
    const [popUpOpened, openSortPopup] = useState(false);
    const [loadingProfessionals, toggleLoadingProfessionals] = useState(true);

    const handleNextPrevButtons = () => {
        if (document.getElementsByClassName("categories-wrapper")[0]) {
            if (document.getElementsByClassName("categories-wrapper")[0].clientWidth < document.getElementsByClassName("categories-wrapper")[0].scrollWidth) {
                togglePrevButton(true);
                toggleNextButton(true);

                if (document.getElementsByClassName("categories-wrapper")[0].scrollLeft === 0) {
                    togglePrevButton(false);
                }
                if (Math.round(document.getElementsByClassName("categories-wrapper")[0].scrollWidth - document.getElementsByClassName("categories-wrapper")[0].clientWidth) === Math.round(document.getElementsByClassName("categories-wrapper")[0].scrollLeft)) {
                    toggleNextButton(false);
                }

            } else {
                togglePrevButton(false);
                toggleNextButton(false);
            }
        }
    };

    const sideScroll = (element, direction, speed, distance, step) => {
        let scrollAmount = 0;
        let slideTimer = setInterval(function () {
            if (direction === 'left') {
                element.scrollLeft -= step;
            } else {
                element.scrollLeft += step;
            }
            scrollAmount += step;
            if (scrollAmount >= distance) {
                handleNextPrevButtons();
                window.clearInterval(slideTimer);
            }
        }, speed);
    }

    const nextClick = () => {
        var container = document.getElementsByClassName('categories-wrapper')[0];
        sideScroll(container, 'right', 25, 129, 10);
    };

    const prevClick = () => {
        var container = document.getElementsByClassName('categories-wrapper')[0];
        sideScroll(container, 'left', 25, 129, 10);
    };

    window.onresize = () => {
        handleNextPrevButtons();
    };

    window.onload = () => {
        handleNextPrevButtons();
    };

    const displayNavs = useCallback((bool) => {
        if (bool) {
            handleNextPrevButtons();
        }
    }, []);

    const getAllNearbyProfessionals = useCallback(async (rating, categoryData, location) => {
        const requestBody = {
            "coordinates": location ? location : [],
            "options": {
                "sortConfig": {
                    "rating": !rating ? -1 : rating
                }
            }
        };
        console.log("selectedCategory ", selectedCategory, categoryData)
        if (categoryData) {
            requestBody.options.categoryId = categoryData;
        } else if (categoryData !== "") {
            if (selectedCategory) {
                requestBody.options.categoryId = selectedCategory;
            }
        }
        toggleDisablePage(true);
        const professionalResult = await getNearbyProfessionals(requestBody);
        toggleDisablePage(false);
        toggleLoadingProfessionals(false);
        if (professionalResult.data.errorCode) {
            toast.error(API_ERROR[professionalResult.data.errorCode]);
            return;
        }
        if (professionalResult.data.data && professionalResult.data.data.errorCode) {
            toast.error(API_ERROR[professionalResult.data.data.errorCode]);
            return;
        }
        console.log("professionalResult ", professionalResult);
        const localProfessionals = [];
        let localProfObj = {};
        const localVendorIds = [];
        if (professionalResult.data.data && professionalResult.data.data.length) {
            professionalResult.data.data.forEach(each => {
                localProfObj = {};
                localProfObj.name = each.vendor.personalInfo.name;
                localProfObj.profilePicture = each.vendor.personalInfo.profilePicture;
                localProfObj.vendorId = each.vendor._id;
                localProfObj.designation = "";
                localProfObj.location = each.addressTitle;
                localProfObj.experience = "";
                localProfObj.rating = 5; // need to change from response
                if (each.vendor.personalInfo.experience.year || each.vendor.personalInfo.experience.month) {
                    const year = each.vendor.personalInfo.experience.year;
                    const month = each.vendor.personalInfo.experience.month;
                    if (year) {
                        localProfObj.experience += `${year} year${year > 1 ? 's' : ''}`;
                    }
                    if (month) {
                        localProfObj.experience += ` ${month} month${month > 1 ? 's' : ''}`;
                    }
                }
                if (each.vendorCategories && each.vendorCategories.length) {
                    each.vendorCategories.forEach((item, index) => {
                        localProfObj.designation += item.title;
                        if (index !== each.vendorCategories.length - 1) {
                            localProfObj.designation += ", ";
                        }
                    });
                }

                if (!localVendorIds.includes(localProfObj.vendorId)) {
                    localVendorIds.push(localProfObj.vendorId);
                    localProfessionals.push({ ...localProfObj });
                }
            });
        }
        updateProfessional([...localProfessionals]);
        // if (localProfessionals.length && rating === null) {
        //     getAllProfessionalCategories();
        // }
    }, [selectedCategory]);

    const getUserLocation = useCallback(async (localCatId) => {
        const localCat = localCatId ? localCatId : null;
        if (window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition((position) => {
                if (position && position.coords && position.coords.latitude && position.coords.longitude) {
                    updateUserLocation([position.coords.longitude, position.coords.latitude]);
                    getAllNearbyProfessionals(null, localCat, [position.coords.longitude, position.coords.latitude]);
                }
            }, (error) => {
                getAllNearbyProfessionals(null, localCat, []);
                if (error.code === 1) {
                    // denied
                    // need toastr
                    console.log("Not able access your current location. If you have denied the permission then please refresh the page or Please change the location access from your browser's settings");
                }
            });
        } else {
            getAllNearbyProfessionals(null, localCat, []);
            console.log("Geolocation is not supported by this browser.");
        }
    }, [getAllNearbyProfessionals]);

    const getAllProfessionalCategories = useCallback(async () => {
        toggleDisablePage(true);
        const categoryResult = await getProfessionalCategories();
        toggleDisablePage(false);
        if (categoryResult.data.errorCode) {
            toast.error(API_ERROR[categoryResult.data.errorCode]);
            return;
        }
        if (categoryResult.data.data && categoryResult.data.data.errorCode) {
            toast.error(API_ERROR[categoryResult.data.data.errorCode]);
            return;
        }
        console.log("categoryResult ", categoryResult);
        const localCategories = [];
        let localCatObj = {};

        const urlParams = new URLSearchParams(window.location.search);
        const catParam = urlParams.get('categoryId');
        let localUrl = '/dashboard?';
        let localCatId = null;
        let localIndex = 0;

        if (categoryResult.data.data && categoryResult.data.data.length) {
            categoryResult.data.data.forEach((each, ind) => {
                localCatObj = {};
                localCatObj.name = each.title;
                localCatObj.imageUrl = each.icon;
                localCatObj._id = each._id;
                localCatObj.selected = false;
                if (catParam && catParam === each._id) {
                    localIndex = ind;
                }
                localCategories.push({ ...localCatObj });
            });
            localCategories[localIndex].selected = true;
            updateSelectedCategory(localCategories[localIndex]._id);
            updateCategory([...localCategories]);
        }

        if (catParam) {
            localCatId = catParam;
        } else if (localCategories.length) {
            localCatId = localCategories[0]._id;
        }

        localUrl += `categoryId=${localCatId}`;

        props.history.push(localUrl);

        setTimeout(() => {
            displayNavs(true);
        }, 1000);
        getUserLocation(localCatId);
    }, [getUserLocation, displayNavs, props.history]);

    useEffect(() => {
        if (!pageInitialized) {
            getAllProfessionalCategories();
            updatePageInitialization(true);
        }
    }, [getAllProfessionalCategories, pageInitialized]);

    const changeSort = (sortConfig) => {
        toggleSort(sortConfig);
        getAllNearbyProfessionals(sortConfig ? 1 : -1, selectedCategory, userLocation);
    };

    const selectCategory = (categoryData) => {
        const localCategories = [...categories];
        if (categoryData.selected) {
            // updateSelectedCategory("");
            // getAllNearbyProfessionals(sortDown ? 1 : -1, "", userLocation);

            return;
        } else {
            updateSelectedCategory(categoryData._id);
            getAllNearbyProfessionals(sortDown ? 1 : -1, categoryData._id, userLocation);
            props.history.push(`/dashboard?categoryId=${categoryData._id}`);
        }
        localCategories.forEach(each => {
            if (each._id === categoryData._id) {
                each.selected = !each.selected;
            } else {
                each.selected = false;
            }
        });
        updateCategory([...localCategories]);
    };

    const showLocationError = () => {
        if (!userLocation.length) {
            toast.error("Not able access your current location. If you have denied the permission then please change the location access from your browser's settings and refresh the page.");
        }
    };

    useEffect(() => {
        window.onclick = (el) => {
            if (el.target &&
                el.target.className &&
                el.target.className.indexOf &&
                el.target.className.indexOf('dropdown-toggle') <= -1) {
                openSortPopup(false);
            }
        }
    });

    return (
        <div className={`row dashboard ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
            <div className="col-lg-12">
                <div className={`row ml-0 mr-0 categories-heading pb-0`} onMouseEnter={() => { displayNavs(true) }}>
                    <h4 className={`col-7 pl-0 mb-0 pt-3 home-page-header display-inline-block`}>Categories</h4>
                    {categories.length ?
                        <div className="col-5 pr-0 text-right category-arrows" onMouseEnter={() => { displayNavs(true) }}>
                            <button className="prev-button-wrapper" onClick={prevClick} id="slideprev" disabled={!showPrevButtons}>
                                <i class="fas fa-arrow-left"></i>
                            </button>
                            <button className="next-button-wrapper" onClick={nextClick} id="slide" disabled={!showNextButtons}>
                                <i class="fas fa-arrow-right"></i>
                            </button>
                        </div> : null}
                </div>
                <div className="whole-categories-wrapper">
                    <div className={`categories-wrapper pl-5`} style={{ margin: 0 }}>
                        {categories.length ? categories.map((category, index) => {
                            return <div className={`custom-card ${index === categories.length - 1 ? 'mr-0' : ''} ${category.selected ? 'selected-card' : ''}`} key={index} onMouseEnter={() => { displayNavs(true) }} onClick={() => selectCategory(category)}>
                                <div className="col-lg-12 p-0">
                                    <div style={{ backgroundImage: "url(" + category.imageUrl + ")" }} className="background-image-category"></div>
                                </div>
                                <div className={`col-lg-12 pl-0 pr-0 category-name ${category.selected ? 'selected-card-name' : ''}`}>
                                    {category.name}
                                </div>
                            </div>
                        }) : null}

                    </div>
                </div>

                <div className="col-12 categories-heading mt-3" onMouseEnter={() => { displayNavs(true) }}>
                    <h4 className={`mb-0 home-page-header`}>Professionals Near You <span className="professional-count">({professionals.length} Professional{professionals.length > 1 ? "s" : ""} Found)</span></h4>
                    {professionals.length ?
                    <ul className="navbar-nav custom-left-nav">
                        <li className={`nav-item dropdown ${popUpOpened ? 'show' : ''}`}>
                            <img src={require('../../../images/sort.png')} className="sort-down nav-link dropdown-toggle" alt="sort down" onClick={() => openSortPopup(!popUpOpened)} />
                            <div className={`dropdown-menu ${popUpOpened ? 'show dropdown-menu-opened' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                                <span className={`dropdown-item ${!sortDown ? 'active' : ''}`} onClick={() => changeSort(false)}>Sort By Rating (Desc)</span>
                                <span className={`dropdown-item ${sortDown ? 'active' : ''}`} onClick={() => changeSort(true)}>Sort By Rating (Asc)</span>
                                {!userLocation.length ?
                                    <span className="dropdown-item" onClick={showLocationError}>Sort By Location</span> : null}
                            </div>
                        </li>
                    </ul> : null}

                </div>
                <div className="col-12" onMouseEnter={() => { displayNavs(true) }}>
                    <div className="row" style={{ margin: 0 }}>
                        {professionals.length ?
                            professionals.map((professional, index) => {
                                return <div className={`col-md-6 col-xs-12 professional-wrapper
                                ${index % 2 === 0 ? 'dashboard-more-768-pr-15' : ''}
                                ${index !== professionals.length - 1 && index !== professionals.length - 2 ? 'dashboard-more-768-pb-15' : ''}
                                ${index !== professionals.length - 1 ? 'dashboard-less-768-pb-15' : ''}
                                `} key={index} onClick={() => props.history.push(`/professional-details/${professional.vendorId}`)}>
                                    <div className="row" style={{ margin: 0 }}>
                                        <div className="booking-profile-image">
                                            {professional.profilePicture ?
                                                <div style={{ backgroundImage: "url(" + professional.profilePicture + ")" }} className="background-image-professional" /> :
                                                <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-professional" />}
                                        </div>
                                        <div className="booking-details-wrapper">
                                            <div className="professional-name">{professional.name}</div>
                                            <div className="professional-designation">{professional.designation}</div>
                                            <div className="professional-location">{professional.location}</div>
                                            <div className="professional-exp">{professional.experience}
                                                &nbsp;Exp
                                                {/* <span>
                                                    <img src={require('../../../images/favourite2_Fill.svg')} alt="average rating" />
                                                    {professional.rating}
                                                </span> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                            : <div className="text-center well col-12 color-black">
                                {!loadingProfessionals ? 'No Professionals Available!' : 'loading...'}
                    </div>}
                    </div>
                </div>
            </div>


            {/* <div className="col-lg-4 categories-heading" onMouseEnter={() => { displayNavs(false)}}>
                <h4 className={`mb-0`}>Premium Listings</h4>
                <div className="col-12 premium-listing-wrapper">
                    <div className="row" style={{margin:0}}>
                    {dummyProfessionals.length ? 
                            dummyProfessionals.map((professional, index) => {
                                return <div className="col-12 professional-wrapper pb-15" key={index}>
                                    <div className="row" style={{margin: 0}}>
                                        <div className="col-3">
                                            <div style={{backgroundImage: "url(" + professional.profilePicture + ")"}} className="background-image-premium"></div>
                                        </div>
                                        <div className="col-9">
                                            <div className="professional-name">{professional.name}</div>
                                            <div className="professional-designation">{professional.designation}</div>
                                        </div>
                                    </div>
                                </div>
                            })
                        : null}
                    </div>
                </div>
            </div> */}
        </div>
    )
};

export default injectIntl(
    connect(
        null,
        auth.actions
    )(Dashboard)
);