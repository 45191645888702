import React, { useEffect, useState, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../store/ducks/auth.duck";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../pages/home/HomePage";
import { LayoutContextProvider } from "../../_metronic";
import * as routerHelpers from "../router/RouterHelpers";
import "./navigation.css";
import { requestLoginOtp, confirmLoginOtp, getProfile, updateProfile } from "../crud/auth.crud";
import { helpSupport } from "../crud/home.crud";
import { REQUIRED_ERROR, VALID_PHONE_ERROR, API_ERROR } from "../config/error-message";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { toast } from 'react-toastify';
import { API_SUCCESS } from "../config/success-message";
import {urls} from "../config/constants";
import LogoutModal from "./logout-modal";

const closeImage = require('../../images/modal-close-icon.png');

const dummyProfile = {
  name: "",
  profilePicture: "",
  phoneNumber: "",
  dob: "",
  gender: 1,
  isSchoolGoingChild: false
};

const initialProfileError = {
  name: "",
  phoneNumber: ""
};

const Routes = withRouter(({ history, ...props }) => {
  const [phoneNumber, updatePhoneNumber] = useState("");
  const [phoneNumberError, updatePhoneError] = useState("");
  const [isLoginTermChecked, changeCheckbox] = useState(false);
  const [otpDetails, updateOtpDetails] = useState({});
  const [otpText, updateOtp] = useState('');
  // const [menuOpened, toggleMenu] = useState(false);
  const [profileOpened, toggleProfile] = useState(false);
  const [isMenuCollapsed, toggleCollapse] = useState(false);
  const [displayModal, toggleModal] = useState(false);
  const [displayHelpModal, toggleHelpModal] = useState(false);
  const [displayLoginOtp, toggleLogin] = useState(false);
  const [displayProfileModal, openProfileModal] = useState(false);
  const [profileDetails, updateProfileDetails] = useState({ ...dummyProfile });
  const [selectedDate, handleDateChange] = useState(new Date());
  const [profileDetailsError, updateProfileDetailsError] = useState({ ...initialProfileError });
  const [disablePage, toggleDisablePage] = useState(false);
  const [uploadedProfileImage, updateProfileImage] = useState("");
  const [currentPage, updateCurrentPage] = useState("/dashboard");
  const [helpObject, updateHelpObject] = useState({
    description: "",
    type: 1
  });
  const [uploadedHelpImage, updateHelpImage] = useState("");
  const [displayLogoutModal, toggleLogoutModal] = useState(false);

  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);
  const { isAuthorized, menuConfig, userLastLocation, authData } = useSelector(
    ({ auth, urls, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: auth.user != null,
      userLastLocation: routerHelpers.getLastLocation(),
      authData: auth.user
    }),
    shallowEqual
  );

  const getFullDateFormat = (date) => {
    const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
    const month = (date.getMonth() + 1).toString().length === 1 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const openMenu = (bool) => {
    // toggleMenu(bool);
    toggleProfile(false);
  };

  const openProfile = (bool) => {
    toggleProfile(bool);
    // toggleMenu(false);
  };

  const openCollapseMenu = (bool) => {
    toggleCollapse(bool);
    // toggleMenu(false);
    toggleProfile(false);
  };

  const openPage = (path) => {
    const urlParams = new URLSearchParams(window.location.search);
    const catParam = urlParams.get('category');
    const langParam = urlParams.get('language');
    const catIdParam = urlParams.get('categoryId');

    if (path === "/problem-area") {
      let localPath = `${path}?`;
      if (catParam) {
        localPath += `category=${catParam}&`;
      }
      if (langParam) {
        localPath += `language=${langParam}`;
      }
      history.push(localPath);
    } else if (path === "/dashboard") {
      let localPath = `${path}?`;
      if (catIdParam) {
        localPath += `categoryId=${catIdParam}`;
      }
      history.push(localPath);
    } else {
      history.push(path);
    }

    openMenu(false);
    openProfile(false);
    updateCurrentPage(path);
    if (window.innerWidth < 768) {
      openCollapseMenu(false);
    }
  };

  useEffect(() => {
    window.onclick = (el) => {
      if (el.target &&
        el.target.className &&
        el.target.className.indexOf &&
        el.target.className.indexOf('dropdown-toggle') <= -1) {
        openMenu(false);
        openProfile(false);
      }
    }
  });

  const clickLogin = () => {
    toggleModal(true);
    toggleLogin(false);
    updateOtp('');
    updatePhoneNumber("");
    changeCheckbox(false);
  };

  const validatePhone = () => {
    if (!phoneNumber.length) {
      updatePhoneError(REQUIRED_ERROR);
      return false;
    }
    if (phoneNumber.length < 10) {
      updatePhoneError(VALID_PHONE_ERROR);
      return false;
    }
    updatePhoneError("");
    return true;
  };

  const getOtp = async () => {
    if (validatePhone()) {
      toggleDisablePage(true);
      const result = await requestLoginOtp({
        "phoneNumber": phoneNumber,
        "countryCode": "IN",
        "dialCode": "+91"
      });
      toggleDisablePage(false);
      console.log("result ", result);
      if (result.data.errorCode) {
        toast.error(API_ERROR[result.data.errorCode]);
        return;
      }
      if (result.data.data && result.data.data.otp) {
        updateOtpDetails({ ...result.data.data });
        toggleLogin(true);
        toast.info(`Your otp is: ${result.data.data.otp}`);
      }
    }
  };

  const confirmOtp = async () => {
    toggleDisablePage(true);
    const result = await confirmLoginOtp({
      customerAuthId: otpDetails.customerAuthId,
      otp: otpText
    });
    toggleDisablePage(false);
    if (result.data.errorCode) {
      toast.error(API_ERROR[result.data.errorCode]);
      return;
    }
    toast.success(API_SUCCESS.LOGIN_SUCCESS);
    console.log("result1 ", result);
    props.login(result.data.data);
    toggleModal(false);
  };

  const resendOtp = () => {
    getOtp();
    updateOtp("");
  };

  const updateProfileModal = useCallback(async (bool) => {
    if (bool) {
      toggleDisablePage(true);
      const profileResult = await getProfile();
      toggleDisablePage(false);
      if (profileResult.data.errorCode) {
        toast.error(API_ERROR[profileResult.data.errorCode]);
        return;
      }
      if (profileResult.data.data && profileResult.data.data.errorCode) {
        toast.error(API_ERROR[profileResult.data.data.errorCode]);
        return;
      }
      console.log("profileResult ", profileResult);
      const localProfileDetails = {};
      if (profileResult.data.data && profileResult.data.data.personalInfo) {
        localProfileDetails.name = profileResult.data.data.personalInfo.name;
        localProfileDetails.phoneNumber = profileResult.data.data.personalInfo.phone.number;
        localProfileDetails.profilePicture = profileResult.data.data.personalInfo.profilePicture;
        localProfileDetails.gender = 0;
        localProfileDetails.hasChildren = profileResult.data.data.hasChildren ? profileResult.data.data.hasChildren : false;
        if (profileResult.data.data.personalInfo.gender) {
          localProfileDetails.gender = profileResult.data.data.personalInfo.gender;
        }
        if (profileResult.data.data.personalInfo.dob) {
          handleDateChange(new Date(profileResult.data.data.personalInfo.dob));
        }
      }
      updateProfileDetails({ ...localProfileDetails });
      openProfileModal(bool);
    }
  }, []);

  const validateProfile = () => {
    let noError = true;
    const localProfileError = { ...profileDetailsError };

    for (let item in localProfileError) {
      if (item !== "gender") {
        if (!profileDetails[item].trim().length) {
          localProfileError[item] = REQUIRED_ERROR;
          noError = false;
        } else {
          localProfileError[item] = "";
          if (item === "phoneNumber" && profileDetails.phoneNumber.length < 10) {
            localProfileError.phoneNumber = VALID_PHONE_ERROR;
            noError = false;
          }
        }
      }
    }

    updateProfileDetailsError({ ...localProfileError });
    return noError;
  };

  const updateProfileData = async () => {
    if (!validateProfile()) {
      return;
    }
    const requestBody = {
      name: profileDetails.name,
      phoneNumber: profileDetails.phoneNumber,
      countryCode: "IN",
      dialCode: "+91",
      hasChildren: profileDetails.hasChildren
    };
    let imageFile = "";
    if (selectedDate) {
      requestBody.dob = getFullDateFormat(new Date(selectedDate));
    }
    if (profileDetails.gender) {
      requestBody.gender = profileDetails.gender.toString();
    }

    imageFile = document.getElementById("profileImage");
    if (imageFile.files[0]) {
      console.log("imageFile.files[0] ", imageFile.files[0]);
      if (imageFile.files[0].type.indexOf("image") > -1) {
        updateProfileImage(URL.createObjectURL(imageFile.files[0]));
      } else {
        toast.error("Please upload image file");
        return;
      }
    }
    //  return;
    toggleDisablePage(true);
    const result = await updateProfile(requestBody, imageFile.files[0]);

    toggleDisablePage(false);
    if (result.data.errorCode) {
      toast.error(API_ERROR[result.data.errorCode]);
      return;
    }
    if (result.data.data && result.data.data.errorCode) {
      toast.error(API_ERROR[result.data.data.errorCode]);
      return;
    }
    toast.success(API_SUCCESS.UPDATE_PROFILE);
    openProfileModal(false);
    props.updateProfile({ ...result.data.data });

    console.log("profileDetails ", profileDetails, selectedDate);
  };

  const changeInput = (text, fieldName) => {
    const localProfileDetails = { ...profileDetails };
    const localProfileError = { ...profileDetailsError };
    localProfileDetails[fieldName] = text;
    localProfileError[fieldName] = "";
    updateProfileDetails({ ...localProfileDetails });
    updateProfileDetailsError({ ...localProfileError });
  };

  useEffect(() => {
    if (history.location.pathname !== "/success-payment" && history.location.pathname !== "/failure-payment" &&
    history.location.pathname !== "/success-mobile-payment" && history.location.pathname !== "/failure-mobile-payment") {
        const el = document.getElementsByClassName("custom-navigation-bar")[0];
        const el1 = document.getElementsByClassName("footer")[0];
        if (el) {
            el.style.visibility = "visible";
        }
        if (el1) {
            el1.style.visibility = "visible";
        }
    }
    for (const i in urls) {
      if (history.location.pathname.indexOf(i) > -1) {
        updateCurrentPage(urls[i]);
      }
    }
    if (isAuthorized) {
      if (authData && authData.personalInfo && !authData.personalInfo.name) {
        updateProfileModal(true);
      }
    }
  }, [isAuthorized, updateProfileModal, authData, history.location.pathname]);

  const openFileUploader = (imageId) => {
    if (document.getElementById(imageId)) {
      document.getElementById(imageId).click();
    }
  };

  const uploadProfileImage = async () => {
    if (document.getElementById("profileImage")) {
      let imageFile = document.getElementById("profileImage");
      if (imageFile.files[0]) {
        if (imageFile.files[0].type.indexOf("image") > -1) {
          updateProfileImage(URL.createObjectURL(imageFile.files[0]));
        } else {
          toast.error("Please upload image file");
          return;
        }
      }
    }
  };

  const logout = () => {
    history.push("/dashboard");
    toggleLogoutModal(false);
    props.logout();
  };

  const updateChildren = () => {
    const localProfileDetails = { ...profileDetails };
    localProfileDetails.hasChildren = !localProfileDetails.hasChildren;
    updateProfileDetails({ ...localProfileDetails });
  };

  const changeHelpDescription = (text) => {
    const localHelp = {...helpObject};
    localHelp.description = text;
    updateHelpObject({...localHelp});
  };

  const changeHelpType = (type) => {
    const localHelp = {...helpObject};
    localHelp.type = type;
    updateHelpObject({...localHelp});
  };

  const uploadHelpImage = async () => {
    if (document.getElementById("helpImage")) {
      let imageFile = document.getElementById("helpImage");
      if (imageFile.files[0]) {
        if (imageFile.files[0].type.indexOf("image") > -1) {
          updateHelpImage(imageFile.files[0].name);
        } else {
          toast.error("Please upload image file");
          return;
        }
      }
    }
  };

  const resetHelpForm = () => {
    updateHelpObject({
      description: "",
      type: 1
    });
    updateHelpImage("");
    let imageFile = "";

    imageFile = document.getElementById("helpImage");
    imageFile.value = '';
  };

  const submitHelp = async () => {
    console.log("helpObj ", helpObject);
    let imageFile = "";

    imageFile = document.getElementById("helpImage");
    if (imageFile.files[0]) {
      console.log("imageFile.files[0] ", imageFile.files[0]);
      if (imageFile.files[0].type.indexOf("image") > -1) {
        updateHelpImage(URL.createObjectURL(imageFile.files[0]));
      } else {
        toast.error("Please upload image file");
        return;
      }
    }
    //  return;
    toggleDisablePage(true);
    const result = await helpSupport(helpObject, imageFile.files[0]);

    toggleDisablePage(false);
    if (result.data.errorCode) {
      toast.error(API_ERROR[result.data.errorCode]);
      return;
    }
    if (result.data.data && result.data.data.errorCode) {
      toast.error(API_ERROR[result.data.data.errorCode]);
      return;
    }
    toast.success(API_SUCCESS.COMPLAIN_SUBMITTED);
    toggleHelpModal(false);
    resetHelpForm();
  };
  

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div>
          <div className={`col-md-12 custom-navigation-bar pad-0-below-768 ${disablePage ? "pointer-none" : ""}`}>
            <nav className="navbar navbar-expand-md navbar-light custom-left-navigation-bar pad-0-below-768">
              <span className="navbar-brand">
                <span className="logo-text-1">my</span><span className="logo-text-2">Academy</span>
                {/* <img src={require("../../images/nav-logo.png")} className="nav-logo" alt="nav logo" style={{ cursor: "pointer" }} onClick={() => openPage("/dashboard")} /> */}
              </span>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" onClick={() => openCollapseMenu(!isMenuCollapsed)}>
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className={`collapse navbar-collapse ${isMenuCollapsed ? 'show' : ''}`} id="navbarNavDropdown">
                <ul className="navbar-nav ml-auto margin-top-less-than-768">
                  {/* <li className="nav-item border-top-less-than-768">
                    <span className="nav-link"><i className="fa fa-bell" aria-hidden="true"></i></span>
                  </li> */}
                  <li className={`nav-item border-top-less-than-768 ${currentPage === '/dashboard' ? 'active' : ''}`}>
                    <span className="nav-link" onClick={() => openPage('/dashboard')}>EXPLORE <span className="sr-only">(current)</span></span>
                  </li>
                  {/* <li className={`nav-item border-top-less-than-768 ${currentPage === '/problem-area' ? 'active' : ''}`}>
                    <span className="nav-link" onClick={() => openPage('/problem-area')}>PROBLEM AREAS</span>
                  </li> */}
                  {isAuthorized ?
                    <li className={`nav-item border-top-less-than-768 ${currentPage === '/booking-list' ? 'active' : ''}`}>
                      <span className="nav-link" onClick={() => openPage('/booking-list')}>MY BOOKINGS</span>
                    </li> : null}
                  {/* {isAuthorized ?
                  <li className={`nav-item border-top-less-than-768 show-more-than-768 dropdown ${menuOpened ? 'show' : ''}`}>
                    <span className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => openMenu(!menuOpened)}>
                      MENU
                    </span>
                    <div className={`dropdown-menu ${menuOpened ? 'show' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                      <span className={`dropdown-item`} onClick={() => toggleHelpModal(true)}>Help & Support</span>
                    </div>
                  </li> : null} */}
                  {!isAuthorized ?
                    <>
                      <li className={`nav-item border-top-less-than-768 show-less-than-768`}>
                        <span className="nav-link" onClick={clickLogin}>LOGIN</span>
                      </li>
                      <li className="nav-item border-top-less-than-768 show-more-than-768 login-button">
                        <button type="button" className="btn btn-sm btn-primary" data-toggle="modal" data-target="#exampleModalCenter" onClick={clickLogin}>
                          LOGIN
                      </button>
                      </li>
                    </> :
                    <>
                      <li className="nav-item border-top-less-than-768 show-less-than-768">
                        <span className="nav-link" onClick={() => updateProfileModal(true)}>PROFILE</span>
                      </li>
                      <li className="nav-item border-top-less-than-768 show-less-than-768">
                        <span className="nav-link" onClick={() => toggleHelpModal(true)}>Help & Support</span>
                      </li>
                      <li className="nav-item border-top-less-than-768 show-less-than-768">
                        <span className="nav-link" onClick={() => toggleLogoutModal(true)}>LOG OUT</span>
                      </li>
                      <li className={`nav-item border-top-less-than-768 show-more-than-768 dropdown ${profileOpened ? 'show' : ''}`}>
                        <span className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => openProfile(!profileOpened)}>
                          Hi {authData.personalInfo ? authData.personalInfo.name.split(" ")[0] : ""}
                        </span>
                        <div className={`dropdown-menu ${profileOpened ? 'show dropdown-menu-opened' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                          <span className="dropdown-item" onClick={() => updateProfileModal(true)}>PROFILE</span>
                          <span className="dropdown-item" onClick={() => toggleHelpModal(true)}>Help & Support</span>
                          <span className="dropdown-item" onClick={() => toggleLogoutModal(true)}>LOG OUT</span>
                        </div>
                      </li>
                    </>}
                </ul>

              </div>
            </nav>
          </div>
        </div>
        <div className={`row header-body-wrapper ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
          <div className="col-lg-12 main-page-wrapper">
            <HomePage userLastLocation={userLastLocation} toggleLoginModal={toggleModal} />
          </div>
        </div>
        <LogoutModal displayLogoutModal={displayLogoutModal}
        toggleLogoutModal={toggleLogoutModal} logout={logout} />
        <div className={`modal custom-login-modal login-modal fade ${displayModal ? 'show show-login-modal' : ''} ${disablePage ? "pointer-none" : ""}`} id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div className="modal-content">
              <img src={closeImage} alt="close modal" onClick={() => toggleModal(false)} className="login-modal-close" />
              <div className="modal-body">
                <div className="col-12 login-logo-wrapper">
                  <span className="logo-text-1">my</span><span className="logo-text-2">Academy</span>
                  {/* <img src={require("../../images/logo-blue.png")} style={{ width: "70px", height: "70px" }} alt="login logo" /> */}
                </div>
                <div className="col-12 text-center">
                  <div className="welcome-text mt-2 mb-2">{!displayLoginOtp ? 'Login' : 'Verify OTP'}</div>
                  <div className="sign-in-text">{!displayLoginOtp ? 'Please provide your phone number in order to login, we will send you a 4 digit OTP for verification' : 'Please submit the 4 digit OTP sent to your number for verfication'}</div>
                </div>
                {!displayLoginOtp ?
                  <div className="col-12 login-phone-input mb-3">
                    <div className={`input-group ${phoneNumberError ? 'error-border' : ''}`}>
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">+91</span>
                      </div>
                      <input type="text" className="form-control phone-number-input" placeholder="Phone Number" aria-label="Phone Number" aria-describedby="basic-addon1" value={phoneNumber} onChange={e => { updatePhoneError(""); updatePhoneNumber(e.target.value.replace(/\D/, '')) }} maxLength={10} />
                    </div>
                    {phoneNumberError ?
                      <div className="input-error">{phoneNumberError}</div> : null}
                  </div> :
                  <div className="col-12 login-phone-input">

                    <div className="row form-group login-otp-input-wrapper" style={{ margin: 0 }}>
                      <input type="text" className="form-control phone-number-input" placeholder="Enter OTP" aria-label="Enter OTP" aria-describedby="basic-addon1" value={otpText} onChange={e => { updateOtp(e.target.value.replace(/\D/, '')) }} maxLength={4} />
                      {/* {otpArray.map((item, index) => {
                        return <div className="col-3 pl-0" key={index}>
                          <input type="text" className="form-control" id={`otp${index}`} value={item} onChange={e => modifyOtp(e.target.value.replace(/\D/, ''), index)} maxLength={1} />
                        </div>
                      })} */}
                    </div>
                  </div>}
                {!displayLoginOtp ?
                  <div className="col-12 login-button">
                    <button type="button" className="btn btn-primary" onClick={getOtp} disabled={!isLoginTermChecked || !phoneNumber}>Send OTP</button>
                  </div> :
                  <div className="col-12 login-button">
                    <button type="button" className="btn btn-primary" onClick={confirmOtp} disabled={!otpText}>Verify</button>
                    <button type="button" className="btn btn-link" onClick={resendOtp}>Resend OTP</button>
                  </div>
                }
                {!displayLoginOtp ?
                  <div className="col-12 accept-checkbox-wrapper">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="" checked={isLoginTermChecked} onChange={() => changeCheckbox(!isLoginTermChecked)} />
                      <label className="form-check-label">
                        By Signing in, you accept our <span onClick={() => window.open("https://www.parentcraftindia.com/privacy-policies")}>Privacy policy</span> and <span onClick={() => window.open("https://www.parentcraftindia.com/termsandconditions")}>Terms of use</span>
                      </label>
                    </div>
                  </div> : null}
              </div>
            </div>
          </div>
        </div>
        <div className={`${disablePage ? "pointer-none" : ""} modal login-modal ask-question-modal fade ${displayHelpModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-md" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Help & Support</h5>
              </div>
              <div className="modal-body">
                <div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="help" id="help1" value="Issue" checked={helpObject.type === 1} onChange={() => changeHelpType(1)} />
                    <label className="form-check-label" for="help1">
                      Issue
                      </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="help" id="help2" value="Suggestions" checked={helpObject.type === 2} onChange={() => changeHelpType(2)} />
                    <label className="form-check-label" for="help2">
                      Suggestions
                      </label>
                  </div>
                </div>
                <div>
                  <div className="form-group question-input">
                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="4" placeholder={`Describe the ${helpObject.type === 1 ? 'issue' : 'suggestion'} you have ${helpObject.type === 1 ? 'encountered' : ''}`} value={helpObject.description} onChange={e => changeHelpDescription(e.target.value)}></textarea>
                  </div>
                </div>
                <div>
                  Add image (upload a screenshot of the specific screen on which you {helpObject.type === 1 ? 'are facing an issue' : 'have a suggestion'}.)
                  </div>
                <div className="file-uploader" onClick={() => openFileUploader("helpImage")}>
                  <input type="file" id="helpImage" hidden onChange={uploadHelpImage} />
                  <i className="fa fa-upload" aria-hidden="true"></i>
                </div>
                {uploadedHelpImage ?
                <div className="mt-3 mb-3">{uploadedHelpImage}</div> : null}

                <div className="col-12 login-button">
                  <button type="button" className="btn btn-primary" disabled={!helpObject.description.trim().length} onClick={submitHelp}>Submit</button>
                  <button type="button" class="btn btn-link" onClick={() => { resetHelpForm(); toggleHelpModal(false)}}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${disablePage ? "pointer-none" : ""} modal login-modal ask-question-modal profile-modal fade ${displayProfileModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-sm" role="document">
            <div className="modal-content">
            {isAuthorized && authData && authData.personalInfo && authData.personalInfo.name ?
                  <img src={closeImage} alt="close modal" onClick={() => openProfileModal(false)} className="login-modal-close" /> : null}
              <div className="modal-header">
                <h5 className="modal-title" style={{margin: "6px auto"}}>Profile</h5>
              </div>
              <div className="modal-body">

                {!uploadedProfileImage ?
                  <div className="col-12 p-0 profile-image-wrapper mb-3">
                    {profileDetails.profilePicture ?
                      <div className="profile-image" style={{ backgroundImage: "url(" + profileDetails.profilePicture + ")" }}>
                        <div className="file-uploader">
                          <input type="file" id="profileImage" accept="image/*" hidden onChange={uploadProfileImage} />
                          <i className="fa fa-camera" aria-hidden="true" onClick={() => openFileUploader("profileImage")}></i>
                        </div>
                      </div> :
                      <div className="profile-image" style={{ backgroundImage: "url(" + require("../../images/imgAvtr.png") + ")" }}>
                        <div className="file-uploader">
                          <input type="file" id="profileImage" accept="image/*" hidden onChange={uploadProfileImage} />
                          <i className="fa fa-camera" aria-hidden="true" onClick={() => openFileUploader("profileImage")}></i>
                        </div>
                      </div>
                    }

                  </div>
                  :
                  <div className="col-12 p-0 profile-image-wrapper mb-3">
                    <div className="profile-image" style={{ backgroundImage: "url(" + uploadedProfileImage + ")" }}>
                      <div className="file-uploader">
                        <input type="file" id="profileImage" accept="image/*" hidden onChange={uploadProfileImage} />
                        <i className="fa fa-camera" aria-hidden="true" onClick={() => openFileUploader("profileImage")}></i>
                      </div>
                    </div>
                  </div>
                }
                <div className="mt-3 mb-3">
                  <div className="text-center profile-phone-number">+91 {profileDetails.phoneNumber}</div>
                </div>
                <div className="mb-3">
                  <input type="text" className={`form-control profile-inputs ${profileDetailsError.name ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Full Name" value={profileDetails.name} onChange={e => changeInput(e.target.value, 'name')} />
                  {profileDetailsError.name ?
                    <div className="input-error">{profileDetailsError.name}</div> : null}
                </div>
                <div className="mb-3">
                  <div className="col-12 p-0">
                    <DatePicker value={selectedDate} onChange={handleDateChange} format="yyyy-MM-dd" maxDate={new Date()} />
                  </div>

                </div>
                <div className="mb-3">
                  <div>
                    <label for="exampleFormControlInput1" className="form-label">Gender</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="gender" id="help1" value="Male" checked={profileDetails.gender === 1} onChange={() => changeInput(1, 'gender')} />
                    <label className="form-check-label" for="help1">
                      Male
                      </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="gender" id="help2" value="Female" checked={profileDetails.gender === 2} onChange={() => changeInput(2, 'gender')} />
                    <label className="form-check-label" for="help2">
                      Female
                      </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="gender" id="help2" value="Other" checked={profileDetails.gender === 3} onChange={() => changeInput(3, 'gender')} />
                    <label className="form-check-label" for="help2">
                      Other
                      </label>
                  </div>
                </div>
                <div className="col-12 p-0 accept-checkbox-wrapper">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" checked={profileDetails.hasChildren} onChange={updateChildren} />
                    <label className="form-check-label" for="defaultCheck1">
                      Do you have school going children?
                      </label>
                  </div>
                </div>
                <div className="col-12 p-0 mt-3 text-center">
                  <button type="button" className="btn btn-primary" onClick={updateProfileData}>Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="spacer"></div> */}
        <footer className={`footer ${disablePage ? "pointer-none" : ""}`}>
          <ul className="nav col-md-7 col-xs-12">
            <li className="nav-item">
              <span className="nav-link" onClick={() => openPage('/faq')}>FAQs</span>
            </li>
            <li className="nav-item">
              <span className="nav-link" onClick={() => window.open("https://www.parentcraftindia.com")}>About Us</span>
            </li>
            <li className="nav-item">
              <span className="nav-link" onClick={() => window.open("https://www.parentcraftindia.com/termsandconditions")}>Terms of Use</span>
            </li>
            <li className="nav-item">
              <span className="nav-link" onClick={() => window.open("https://www.parentcraftindia.com/privacy-policies")}>Privacy Policy</span>
            </li>
          </ul>
          <div className="col-md-5 col-xs-12 copyright-text">
            © 2021 Parentcraft India. All rights reserved.
          </div>
        </footer>
        </MuiPickersUtilsProvider>

    </LayoutContextProvider>
  );
});

const mapStateToProps = state => {
  return { data: state };
};

export default injectIntl(
  connect(
    mapStateToProps,
    auth.actions
  )(Routes)
);
