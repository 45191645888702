import React, { useEffect, useState, useCallback } from 'react';
import "./problem-area.css";
import { getProblemCategoryDetails } from "../../crud/qa-forum.crud";
import { toast } from 'react-toastify';
import {
    API_ERROR
} from "../../config/error-message";

const ProblemAreaDetails = (props) => {
    const [problemAreaDetails, updateDetails] = useState({});
    const [disablePage, toggleDisablePage] = useState(false);

    const getAllProblemCategoryDetails = useCallback(async (id) => {
        toggleDisablePage(true);
        const problemAreaResult = await getProblemCategoryDetails(id);
        console.log("problemAreaResult1 ", problemAreaResult);

        toggleDisablePage(false);
        if (problemAreaResult.data.errorCode) {
            toast.error(API_ERROR[problemAreaResult.data.errorCode]);
            return;
        }
        if (problemAreaResult.data.data && problemAreaResult.data.data.errorCode) {
            toast.error(API_ERROR[problemAreaResult.data.data.errorCode]);
            return;
        }

        const problemAreaObj = {};
        if (problemAreaResult.data.data) {
            const localRes = problemAreaResult.data.data;

            if (localRes.languageSet && localRes.languageSet.length) {
                localRes.languageSet.forEach(each1 => {
                    if (each1.languageRef.code === props.match.params.lang) {
                        problemAreaObj._id = localRes._id;
                        problemAreaObj.question = each1.title;
                        problemAreaObj.answer = each1.description;
                        problemAreaObj.highlightedAnswer = each1.highlightedAnswer;
                        problemAreaObj.audio = each1.audioFile;
                    }
                });

                if (!problemAreaObj.question) {
                    localRes.languageSet.forEach(each1 => {
                        if (each1.languageRef.name.trim().toLowerCase() === "english") {
                            problemAreaObj._id = localRes._id;
                            problemAreaObj.question = each1.title;
                            problemAreaObj.answer = each1.description;
                            problemAreaObj.highlightedAnswer = each1.highlightedAnswer;
                            problemAreaObj.audio = each1.audioFile;
                        }
                    });
                }
            }

            if (localRes.problemCategoryRef &&
                localRes.problemCategoryRef.languageSet &&
                localRes.problemCategoryRef.languageSet.length) {
                localRes.problemCategoryRef.languageSet.forEach(each1 => {
                    if (each1.languageRef.code === props.match.params.lang) {
                        problemAreaObj.categoryName = each1.title;
                    }
                });

                if (!problemAreaObj.categoryName) {
                    localRes.problemCategoryRef.languageSet.forEach(each1 => {
                        if (each1.languageRef.name.trim().toLowerCase() === "english") {
                            problemAreaObj.categoryName = each1.title;
                        }
                    });
                }
            }
        }
        updateDetails({ ...problemAreaObj });
    }, [props.match.params]);

    useEffect(() => {
        console.log("params ", props.match.params)
        if (props.match.params.id) {
            getAllProblemCategoryDetails(props.match.params.id);
        } else {
            console.log("error");
        }

    }, [props.match.params, getAllProblemCategoryDetails]);

    return (
        <div className={`row problem-area problem-area-details ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
            <div className="col-lg-12">
                <div className="row" style={{ margin: 0 }}>
                    <div className={`col-12 pb-15`}>
                        <h4 className={`mb-0`} style={{ paddingTop: "7px" }}><i className="fa fa-arrow-left" aria-hidden="true" onClick={() => props.history.goBack()}></i>Problem Details</h4>
                    </div>
                </div>
                <div className="row problem-details-wrapper">
                    {problemAreaDetails.question ?
                        <>
                            <div className="col-12">
                                <div className="problem-category">
                                    {problemAreaDetails.categoryName}
                                    {/* <img src={require('../../../images/favourite2_Fill.svg')} alt="mark favourite" /> */}
                                </div>
                                <div className="problem-question">
                                    {problemAreaDetails.question}
                                </div>
                                <div className="problem-answer">
                                    {problemAreaDetails.answer.split('\n').map((item, key) => {
                                        return <span key={key}>{item}<br /></span>
                                    })}
                                </div>
                                <div className="problem-category text-center">
                                    Copyright &#169; Parentcraft India
                                </div>
                            </div>
                            <div className="col-md-5 col-xs-12">
                                {problemAreaDetails.audio ?
                                    <div className="read-out">
                                        <div>or Listen to the Audio</div>
                                        <audio id="myAudio" controls controlsList="nodownload">
                                            <source src={"https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"} type="audio/mpeg" />
                                        </audio>
                                    </div> : null}
                            </div></> :
                        <div className="text-center well col-12 color-black">
                            No Details Available!
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default ProblemAreaDetails;