import axios from "./axios.interceptor";
import baseUrl from "./api.util";

export const GET_PROFESSIONAL_CATEGORIES = `${baseUrl}/customer/common/vendor-category`;
export const GET_NEARBY_PROFESSIONALS = `${baseUrl}/customer/common/nearby-vendor`;
export const GET_PROFESSIONAL_DETAILS = `${baseUrl}/customer/common/vendor-details`;
export const GET_AVAILABILITY = `${baseUrl}/account/customer/booking/available-slot`;
export const BOOK_SLOT = `${baseUrl}/account/customer/booking/book`;
export const NOTIFICATION = `${baseUrl}/customer/common/global-config`;
export const HELPSUPPORT = `${baseUrl}/account/customer/support`;

export const getProfessionalCategories = async () => {
  const res = await axios.get(`${GET_PROFESSIONAL_CATEGORIES}?skip=0&limit=0`);
  return res;
}

export const getNearbyProfessionals = async (requestBody) => {
    const res = await axios.post(`${GET_NEARBY_PROFESSIONALS}?skip=0&limit=0`, requestBody);
    return res;
}

export const getProfessionalDetails = async (id) => {
  const res = await axios.get(`${GET_PROFESSIONAL_DETAILS}/${id}`);
  return res;
}

export const getAvailability = async (id) => {
  const res = await axios.get(`${GET_AVAILABILITY}/${id}`);
  return res;
}

export const bookSlot = async (id, requestBody) => {
  const res = await axios.post(`${BOOK_SLOT}/${id}`, requestBody);
  return res;
}

export const notification = async () => {
  const res = await axios.get(`${NOTIFICATION}`);
  return res;
}

export const helpSupport = async (requestBody, imageFile) => {
  const formData = new FormData();
  if (imageFile) {
    formData.append('image', imageFile);
  }
  if (requestBody) {
    for (let i in requestBody) {
      formData.append(i, requestBody[i]);
    };
  }
  const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
  };
  const res = await axios.post(`${HELPSUPPORT}`, formData, config);
  return res;
}
